import { Grid } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import { fixAmount } from "../../../untils/shortcut";
const latlong = window.location.origin.includes("kk")
    ? [16.43192755724492, 102.82856609781523]
    : window.location.origin.includes("mdh")
    ? [16.59738946623057, 104.51104253757008]
    : window.location.origin.includes("spb")
    ? [14.651579065119135, 99.93989999520554]
    : [16.43192755724492, 102.82856609781523];
// [14.651579065119135, 99.93989999520554];

function LeafletMapComponent({ subdistrictObj, setLocation, setHName, location }) {
    // console.log("subdistrictObj", subdistrictObj);
    const [map, setMap] = useState(null);

    const highlightFeature = (e) => {
        // console.log(e.target.getBounds().getCenter());
        let layer = e.target;
        const { hname, death } = e.target.feature.properties;

        if (death > 0) {
            setHName(hname);
            setLocation(location);
        }

        layer.setStyle({
            weight: 5,
            color: "#666",
            dashArray: "",
            fillOpacity: 0.7,
        });
        layer.bringToFront();
    };

    const popupFeature = (e) => {
        let layer = e.target;
        const { death, hname } = e.target.feature.properties;

        // console.log(death, location,tam_th);

        if (death > 0) {
            const popupOptions = {
                minWidth: 100,
                maxWidth: 250,
                className: "popup-classname",
            };

            layer
                .bindTooltip(() => {
                    return `<b>${hname} <br> ${fixAmount(death)}</b>`;
                }, popupOptions)
                .openTooltip();
        }
    };

    const onEachFeature = (feature, layer) => {
        layer.on({
            mouseover: popupFeature,
            click: highlightFeature,
        });
    };

    const mapPolygonColorToDensity = (d) => {
        return d > 10000
            ? "#800026"
            : d > 5000
            ? "#BD0026"
            : d > 2000
            ? "#E31A1C"
            : d > 1000
            ? "#FC4E2A"
            : d > 500
            ? "#FD8D3C"
            : d > 200
            ? "#FEB24C"
            : d > 100
            ? "#FED976"
            : d >= 1
            ? "#FFEDA0"
            : "#000";
    };
    const style = (feature) => {
        return {
            fillColor: mapPolygonColorToDensity(feature.properties.death),
            weight: 1,
            opacity: 1,
            color: "white",
            dashArray: "2",
            fillOpacity: 0.5,
        };
    };

    return (
        <>
            <Grid container columns={12}>
                <Grid item xs={12}>
                    <MapContainer
                        scrollWheelZoom={true}
                        center={latlong} // Set your initial map center coordinates
                        zoom={9} // Set your initial zoom level
                        className="mapContainer"
                        ref={setMap}
                        map={map}
                        attributionControl={false}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            maxZoom={19}
                            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {subdistrictObj.length > 0 && <GeoJSON data={subdistrictObj} style={style} onEachFeature={onEachFeature} />}
                    </MapContainer>
                </Grid>
            </Grid>
        </>
    );
}

export default LeafletMapComponent;
