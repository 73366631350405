import { Box, Grid, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import { BtnMap } from "../../../Theme";
import { add, fixAmount, formatNumber, tofieds } from "../../../untils/shortcut";
const latlong = window.location.origin.includes("kk")
    ? [16.43192755724492, 102.82856609781523]
    : window.location.origin.includes("mdh")
    ? [16.59738946623057, 104.51104253757008]
    : window.location.origin.includes("spb")
    ? [14.651579065119135, 99.93989999520554]
    : [16.43192755724492, 102.82856609781523];
function LeafletMapComponent({ subdistrictObj /*,setSelectTambon*/ }) {
    // console.log("subdistrictObj", subdistrictObj);
    const [/*map,*/ setMap] = useState(null);
    const [onselect, setOnselect] = useState([]);

    const highlightFeature = (e) => {
        let layer = e.target;
        const { tam_th } = e.target.feature.properties;
        const dataDashboardAll = e.target.feature.properties.dataDashboardAll;

        if (dataDashboardAll.length > 0) {
            const combinedHname = dataDashboardAll.map((dashboard) => dashboard.hname).join(", ");
            const totalCount = dataDashboardAll.reduce((sum, dashboard) => sum + Number(dashboard.count), 0);

            setOnselect({
                Tumbon: tam_th,
                Count: totalCount,
                Hname: combinedHname,
            });

            layer.setStyle({
                weight: 5,
                color: "#666",
                dashArray: "",
                fillOpacity: 0.7,
            });
            layer.bringToFront();
        }
    };

    const popupFeature = (e) => {
        let layer = e.target;
        const data = e.target.feature.properties.dataDashboardAll;

        if (data.length > 0) {
            const popupContent = data.map((dashboard) => `<b>${dashboard.hname}</b><br>จำนวนผู้เข้ารับบริการ: ${dashboard.count} คน`).join("<br>");

            const popupOptions = {
                minWidth: 100,
                maxWidth: 250,
                className: "popup-classname",
            };

            layer.bindTooltip(popupContent, popupOptions).openTooltip();
        }
    };

    const handleAll = () => {
        // setSelectTambon("");
        setOnselect({
            Tumbon: null,
            Count: null,
        });
    };

    const onEachFeature = (feature, layer) => {
        layer.on({
            mouseover: popupFeature,
            click: highlightFeature,
        });
    };

    const mapPolygonColorToDensity = (d) => {
        return d > 10000
            ? "#800026"
            : d > 5000
            ? "#BD0026"
            : d > 2000
            ? "#E31A1C"
            : d > 1000
            ? "#FC4E2A"
            : d > 500
            ? "#FD8D3C"
            : d > 200
            ? "#FEB24C"
            : d > 100
            ? "#FED976"
            : d > 1
            ? "#FFEDA0"
            : "#000";
    };

    const style = (feature) => {
        return {
            fillColor: mapPolygonColorToDensity(feature.properties.dataDashboardAll[0]?.count),
            weight: 1,
            opacity: 1,
            color: "white",
            dashArray: "2",
            fillOpacity: 0.5,
        };
    };

    // formatter: function (value) {
    //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // },

    return (
        <>
            <Grid container columns={12}>
                <Grid item xs={12}>
                    <Box className="census-info" width="100%">
                        {onselect.Hname ? (
                            <Stack spacing={1} className="flex-center" width="100%">
                                {onselect.Hname.split().map((hname, index) => (
                                    <Typography key={index}>{hname}</Typography>
                                ))}
                                <Typography>จำนวนผู้เข้ารับบริการ: {tofieds(onselect.Count, 0, 0)} คน</Typography>
                            </Stack>
                        ) : (
                            <Stack spacing={1} className="flex-center" width="100%">
                                <Typography>
                                    จำนวนผู้เข้ารับบริการทั้งหมด :{" "}
                                    {tofieds(
                                        subdistrictObj
                                            .map((e) => {
                                                // ใช้ reduce เพื่อบวก `count` ทุกตัวใน dataDashboardAll
                                                const totalCount = e.properties.dataDashboardAll
                                                    .map((item) => Number(item.count) || 0) // แปลงเป็นตัวเลข
                                                    .reduce((acc, curr) => acc + curr, 0); // รวมค่าทั้งหมด
                                                return totalCount;
                                            })
                                            .reduce((acc, curr) => acc + curr, 0),
                                        0,
                                        0
                                    )}
                                    คน
                                </Typography>
                            </Stack>
                        )}
                        <Box className="flex-end info-button">
                            <BtnMap variant="outlined" onClick={handleAll}>
                                ทั้งหมด
                            </BtnMap>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <MapContainer
                        scrollWheelZoom={true}
                        center={latlong} // Set your initial map center coordinates
                        zoom={9} // Set your initial zoom level
                        className="mapContainer"
                        ref={setMap}
                        attributionControl={false}
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={11} minZoom={8} />
                        {subdistrictObj.length > 0 && (
                            <GeoJSON
                                data={subdistrictObj}
                                style={style}
                                onEachFeature={onEachFeature}
                                // onEachFeature={(feature, leafletLayer) => {
                                //      const popupOptions = {
                                //           minWidth: 100,
                                //           maxWidth: 250,
                                //           className: "popup-classname"
                                //      };

                                //      leafletLayer.bindTooltip(() => {
                                //           return `<b>tooltip</b>`
                                //      }, popupOptions).openTooltip();
                                // }}
                            />
                        )}
                    </MapContainer>
                </Grid>
            </Grid>
        </>
    );
}

export default LeafletMapComponent;
