import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GET, GETDATADIABETES, POST_GIS, SELECTORHOSP } from "../../service";
import { getToken } from "../../untils/shortcut";
import { headerTable } from "../../untils/static";
import K111DiabetesCard from "./K111Diabetes/K111DiabetesCard";

function K111Diabetes() {
    const profile = getToken(localStorage.getItem("profile"));
    const year_length = new Date().getFullYear() - 5;
    const yearsData = Array.from({ length: 10 }, (_, index) => {
        return { value: year_length + index };
    });
    const [data, setData] = useState([]);
    const [dataTbl2, setDataTbl2] = useState([]);
    const [dataGraph, setDataGraph] = useState([]);
    const [dataSelect, setDataSelect] = useState("ทั้งหมด");
    const [hname, setHname] = useState("");
    const [hcode, setHcode] = useState("");
    const [year, setYear] = useState(dayjs().year());
    const [loading, setLoading] = useState(false);
    const [selectHosp, setSelectHosp] = useState([]);

    const getK111Diabetes = async (data = {}) => {
        try {
            setLoading(true);
            let res = await POST_GIS(GETDATADIABETES, { hcode, year_quarter: data.year ?? year });
            if (res.success) {
                setData(res.result.tableOne);
                setDataTbl2(res.result.tableTwo);
                setDataGraph(res.result.graph);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getSelectHospital = async () => {
        try {
            let res = await GET(SELECTORHOSP);
            let hospname = profile.location_code + " " + profile.location_name;
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setDataSelect(hospname);
                }
                setSelectHosp(res.result);
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    useEffect(() => {
        getSelectHospital();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getK111Diabetes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hcode, year]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <K111DiabetesCard
                profile={profile}
                data={data}
                dataTbl2={dataTbl2}
                header={headerTable.Diabetes_header}
                dataSelect={dataSelect}
                setDataSelect={setDataSelect}
                hname={hname}
                hcode={hcode}
                setHname={setHname}
                setHcode={setHcode}
                year={year}
                yearsData={yearsData}
                setYear={setYear}
                dataGraph={dataGraph}
                selectHosp={selectHosp}
            />
        </Container>
    );
}

export default K111Diabetes;
