import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DrugCard from "./components/DrugCard";
import BackdropLoad from "../../../components/Backdrop/BackdropLoad";
import { GET, GETDATADRUGCATALOG, POST, SELECTORHOSP } from "../../../service";
import { getToken } from "../../../untils/shortcut";

function DrugCatalog() {
    const [dataDrug, setDataDrug] = useState([]);
    const [hcode, setHcode] = useState("");
    const [dataSelect, setDataSelect] = useState("ทั้งหมด");
    const [selectHosp, setSelectHosp] = useState([]);
    const [loading, setLoading] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));

    const getDataDrugCatalog = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATADRUGCATALOG, { hcode });
            if (res.success) {
                setDataDrug(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const selectData = async () => {
        try {
            setLoading(true);
            let res = await GET(SELECTORHOSP);
            let hospname = profile.location_code + " " + profile.location_name;
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setDataSelect(hospname);
                }
                setSelectHosp(res.result);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    useEffect(() => {
        getDataDrugCatalog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hcode]);

    useEffect(() => {
        selectData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <DrugCard
                profile={profile}
                dataDrug={dataDrug}
                dataSelect={dataSelect}
                selectHosp={selectHosp}
                setHcode={setHcode}
                setDataSelect={setDataSelect}
            />
        </Container>
    );
}
export default DrugCatalog;
