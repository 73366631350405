import {
    Box,
    Button,
    Collapse,
    CssBaseline,
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    debounce,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { GET, GETUSERBYID, LOGOUT, POST, SEARCHLOCATION, UPDATESTATUSUSER } from "../service.js";

import { useNavigate } from "react-router-dom";

import { ArrowDownIcon, ArrowUpIcon, HomeDrawerIcon, LogoutDrawerIcon, MenuDrawerIcon, UserIcon } from "../untils/icons.js";

import { getToken } from "../untils/shortcut.js";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//theme
import { Settings } from "@mui/icons-material";
import Swal from "sweetalert2";
import { AppBar, DrawerHeader, Main, drawerWidth } from "../Theme.jsx";
import DialogEditProfile from "../components/Dialog/DialogEditProfile.js";
import { useRoute } from "../state/ManageRoute.js";
import { menuSideBar } from "../untils/static.js";
const pathapi = process.env.REACT_APP_SERVICE;

function CustomDrawer({ children }) {
    let screen = JSON.parse(localStorage.getItem("screen"));
    const profile = getToken(localStorage.getItem("profile"));

    const [setMenu, menu, setSystem, system] = useRoute((state) => [state.setMenu, state.menu, state.setSystem, state.system]);

    const path = window.location.pathname;
    const strs = path.split("/");

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const [dataLocation, setDataLocation] = useState([]);
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [location, setLocation] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);
    const [searchLoca, setSearchLoca] = useState("");
    const [inputLoca, setInputLoca] = useState("");
    const [openEdit, setOpenEdit] = useState(false);

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleRoute = (path) => {
        return () => {
            // navigate(path, { state: { profile: profile } });
            navigate(path);
        };
    };

    const logout = async () => {
        try {
            let res = await GET(LOGOUT);
            if (res.success) {
                localStorage.removeItem("profile");
                localStorage.removeItem("screen");
                localStorage.removeItem("timeOut");
                navigate("/Login");
            } else {
                console.log(res.result);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getUserId = async () => {
        try {
            //setLoading(true)
            let res = await POST(GETUSERBYID, { user_id: profile.user_id });
            if (res.success) {
                // let data_screen = res.result.screen.filter((e) => e.status === 1);
                window.localStorage.removeItem("screen");
                window.localStorage.setItem("screen", JSON.stringify(res.result.screen_new));
                window.localStorage.removeItem("profile");
                window.localStorage.setItem("profile", JSON.stringify(res.result.token));
                window.location.reload();
                //setLoading(false)
            } else {
                //setLoading(false)
                alert(res.result);
            }
        } catch (error) {
            //setLoading(false);
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        }
    };

    const getUser = async () => {
        try {
            //setLoading(true)
            // console.log(profile);
            let res = await POST(GETUSERBYID, { user_id: profile.user_id });
            if (res.success) {
                let data_user = res.result;
                setLocation(data_user.location_id);
                setName(data_user.name);
                setLastname(data_user.lastname);
                setEmail(data_user.email);
                setSearchLoca(data_user.location_id !== null ? `${data_user.location_code} ${data_user.location_name}` : null);
                //setLoading(false)
            } else {
                //setLoading(false)
                alert(res.result);
            }
        } catch (error) {
            //setLoading(false);
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        }
    };

    const handleUpdateUser = async () => {
        // console.log(name, lastname, location, email, password, confirmPassword);
        try {
            //setLoading(true)
            let data = [];
            if (errorEmail) {
                Swal.fire({
                    icon: "warning",
                    text: `กรุณาตรวจสอบข้อมูลให้ถูกต้อง`,
                    confirmButtonText: "ตกลง",
                });
            } else {
                if (password) {
                    if (confirmPassword === password) {
                        data.push({
                            name,
                            lastname,
                            location_id: location,
                            email,
                            password,
                        });
                        let res = await POST(UPDATESTATUSUSER, {
                            user_id: profile.user_id,
                            data: data[0],
                        });
                        if (res.success) {
                            //setLoading(false);
                            Swal.fire({
                                icon: "success",
                                text: `แก้ไขบัญชีผู้ใช้งานสำเร็จ`,
                                confirmButtonText: "ตกลง",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    getUserId();
                                }
                            });
                        } else {
                            //setLoading(false);
                            Swal.fire({
                                icon: "warning",
                                text: `แก้ไขบัญชีผู้ใช้งานไม่สำเร็จ`,
                                confirmButtonText: "ตกลง",
                            });
                        }
                    } else {
                        //setLoading(false);
                        Swal.fire({
                            icon: "error",
                            text: `รหัสผ่านไม่ตรงกัน`,
                            confirmButtonText: "ตกลง",
                        });
                    }
                } else {
                    data.push({
                        name,
                        lastname,
                        location_id: location,
                        email,
                    });
                    let res = await POST(UPDATESTATUSUSER, {
                        user_id: profile.user_id,
                        data: data[0],
                    });
                    if (res.success) {
                        //setLoading(false);
                        Swal.fire({
                            icon: "success",
                            text: `แก้ไขบัญชีผู้ใช้งานสำเร็จ`,
                            confirmButtonText: "ตกลง",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                getUserId();
                            }
                        });
                    } else {
                        //setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            text: `แก้ไขบัญชีผู้ใช้งานไม่สำเร็จ`,
                            confirmButtonText: "ตกลง",
                        });
                    }
                }
            }
        } catch (error) {
            //setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleChangeText = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        if (name === "email") {
            const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
            if (value !== undefined && value.match(regex)) {
                setErrorEmail(false);
                return value;
            } else {
                setErrorEmail(true);
            }
        }

        return value;
    };

    const fetchLocation = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    let res = await POST(SEARCHLOCATION, { search: request });
                    if (res.success) {
                        // console.log("res:", res.result);
                        setDataLocation(res.result.data);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: `ค้นหาไม่สำเร็จ`,
                            confirmButtonText: "ตกลง",
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: "warning",
                        text: `${error}`,
                        confirmButtonText: "ตกลง",
                    });
                }
            }, 400),
        []
    );

    useEffect(() => {
        if (inputLoca.length > 3) {
            fetchLocation(inputLoca);
        } else if (!inputLoca) {
            fetchLocation(inputLoca);
            return undefined;
        }
    }, [searchLoca, inputLoca, fetchLocation]);

    const handleClickOpenEdit = () => {
        //call api get data
        setErrorEmail(false);
        getUser();
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleClickOpensub = (url, show) => {
        //     console.log(path_name, show);
        // console.log("menu:", menu);
        let newData = menu.map((item) => {
            return item.url === url ? { ...item, expand: show === false ? true : false } : item;
        });
        setMenu(newData);
    };

    useEffect(() => {
        if (strs[1] !== "Homepage") {
            let obj = screen.find((f) => f.url === `/${strs[1]}`).type;
            let newMenu = screen.filter((e) => e.type === obj);
            setSystem(MANAGEURL_TYPE[obj].name);
            setMenu(newMenu);
        }

        // For Dev//
        // if (strs[1] !== "Homepage") {
        //     let obj = menuSideBar.menu_all.find((f) => f.url === `/${strs[1]}`)?.type;
        //     if (obj) {
        //         let newMenu = menuSideBar.menu_all.filter((e) => e.type === obj);
        //         setSystem(MANAGEURL_TYPE[obj].name);
        //         setMenu(newMenu);
        //     }
        // }
    }, []);

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <Grid container columns={12}>
                            <Grid item xs={1} className="flex-center">
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                    sx={{ ml: 0, ...(open && { display: "none" }) }}
                                >
                                    <MenuDrawerIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="flex-start" display={{ xs: "none", sm: "flex" }}>
                                <Typography variant="h6" noWrap component="div">
                                    {`${strs[1]}` === "Homepage" ? "หน้าหลัก" : system}
                                </Typography>
                            </Grid>
                            <Grid item xs={11} sm={3} md={3} className="flex-end">
                                <React.Fragment>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Tooltip>
                                            <Button
                                                onClick={handleClick}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={openMenu ? "account-menu" : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openMenu ? "true" : undefined}
                                                variant="text"
                                            >
                                                <Stack direction="row" spacing={1}>
                                                    <UserIcon />
                                                    <Stack>
                                                        <Typography sx={{ textDecoration: "underline" }} color="#fff" noWrap component="div">
                                                            {profile.name} {profile.lastname}
                                                        </Typography>
                                                        <Typography color="#fff" fontSize="12px" noWrap component="div" className="flex-end">
                                                            {profile.role_name}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={openMenu}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: "visible",
                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                mt: 1.5,
                                                "& .MuiAvatar-root": {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                "&::before": {
                                                    content: '""',
                                                    display: "block",
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: "background.paper",
                                                    transform: "translateY(-50%) rotate(45deg)",
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                handleClose();
                                                handleClickOpenEdit();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <Settings fontSize="small" />
                                            </ListItemIcon>
                                            แก้ไขข้อมูล
                                        </MenuItem>
                                        <ListItem disablePadding onClick={logout}>
                                            <MenuItem>
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 2 : "auto",
                                                        justifyContent: "start",
                                                    }}
                                                >
                                                    <LogoutDrawerIcon fontSize="medium" />
                                                </ListItemIcon>
                                                ออกจากระบบ
                                            </MenuItem>
                                        </ListItem>
                                    </Menu>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            width: drawerWidth,
                            boxSizing: "border-box",
                        },
                    }}
                    // variant="persistent"
                    variant="temporary"
                    anchor="left"
                    open={open}
                    onClose={handleDrawerToggle}
                >
                    <Toolbar>
                        <List>
                            <ListItem disablePadding sx={{ display: "block" }}>
                                <Grid item xs={12} className="flex-start">
                                    <Typography variant="caption" fontSize={18}>
                                        {`${strs[1]}` === "Homepage" ? "หน้าหลัก" : system}
                                    </Typography>
                                </Grid>
                            </ListItem>
                            <small style={{ cursor: "pointer", color: "blue" }} onClick={() => window.open("https://line.me/R/ti/p/@250dbcfz")}>
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <ArrowForwardIosIcon fontSize="small" />
                                    Line Notification
                                </span>
                            </small>
                            <small
                                style={{ cursor: "pointer", color: "blue", marginTop: "10px" }}
                                onClick={() => window.open(`${pathapi}/api/image/guidebook.pdf?time=${new Date()}`)}
                            >
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <ArrowForwardIosIcon fontSize="small" />
                                    คู่มือการใช้งาน
                                </span>
                            </small>
                        </List>
                    </Toolbar>
                    <Divider />
                    <List>
                        <ListItem disablePadding onClick={handleRoute(`/Homepage`)}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                                onClick={handleDrawerToggle}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 2 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <HomeDrawerIcon fontSize="medium" />
                                </ListItemIcon>
                                <ListItemText primary="หน้าหลัก" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        {menu.map((e, i) => (
                            <div key={i}>
                                {e.sub === true ? (
                                    <>
                                        <ListItem disablePadding onClick={() => handleClickOpensub(e.url, e.expand)}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? "initial" : "center",
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 2 : "auto",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    {/* <CardMedia
                                                                           sx={{ height: 24, width: 24, objectFit: "contain" }}
                                                                           component="img"
                                                                           alt="Logo"
                                                                           image={e.icon}
                                                                      /> */}
                                                    <i className={e.icon} style={{ color: "var(--teal-A800)" }}></i>
                                                </ListItemIcon>
                                                <ListItemText primary={e.path_name} />
                                                {e.expand ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={e.expand} timeout="auto" unmountOnExit>
                                            {e.submenu.map((el, i) => (
                                                <ListItem disablePadding onClick={handleRoute(`${el.url}`, el.url)} key={i + 1}>
                                                    <ListItemButton
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent: open ? "initial" : "center",
                                                            px: 2.5,
                                                            ml: 5,
                                                        }}
                                                        onClick={handleDrawerToggle}
                                                    >
                                                        <ListItemText primary={el.path_name} />
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </Collapse>
                                    </>
                                ) : (
                                    <ListItem disablePadding onClick={handleRoute(`${e.url}`)} key={i + 1}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? "initial" : "center",
                                                px: 2.5,
                                            }}
                                            onClick={handleDrawerToggle}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 2 : "auto",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {/* <CardMedia
                                                                      sx={{ height: 24, width: 24, objectFit: "contain" }}
                                                                      component="img"
                                                                      alt="Logo"
                                                                      image={e.icon}
                                                                 /> */}
                                                <i className={e.icon} style={{ color: "var(--teal-A800)" }}></i>
                                            </ListItemIcon>
                                            <ListItemText primary={e.path_name} />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </div>
                        ))}
                        {/* {menu.map((e, i) => (
                                   <div key={i}>
                                        <ListItem disablePadding onClick={handleRoute(`${e.link}`)}>
                                             <ListItemButton
                                                  sx={{
                                                       minHeight: 48,
                                                       justifyContent: open ? "initial" : "center",
                                                       px: 2.5,
                                                  }}
                                                  onClick={handleDrawerToggle}
                                             >
                                                  <ListItemIcon
                                                       sx={{
                                                            minWidth: 0,
                                                            mr: open ? 2 : "auto",
                                                            justifyContent: "center",
                                                       }}
                                                  >
                                                       <CardMedia
                                                            sx={{ height: 24, width: 24, objectFit: "contain" }}
                                                            component="img"
                                                            alt="Logo"
                                                            image={e.icon}
                                                       />
                                                  </ListItemIcon>
                                                  <ListItemText primary={e.path_name} />
                                             </ListItemButton>
                                        </ListItem>
                                   </div>
                              ))} */}
                        <ListItem disablePadding onClick={logout}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                                onClick={handleDrawerToggle}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 2 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <LogoutDrawerIcon fontSize="medium" />
                                </ListItemIcon>
                                <ListItemText primary="ออกจากระบบ" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>
                <Main open={open}>
                    <DrawerHeader />
                    {children}
                </Main>
            </Box>
            <DialogEditProfile
                dataLocation={dataLocation}
                handleChangeText={handleChangeText}
                handleUpdateUser={handleUpdateUser}
                errorEmail={errorEmail}
                location={location}
                name={name}
                lastname={lastname}
                email={email}
                searchLoca={searchLoca}
                setName={(e) => setName(e)}
                setLastname={(e) => setLastname(e)}
                setLocation={(e) => setLocation(e)}
                setEmail={(e) => setEmail(e)}
                setPassword={(e) => setPassword(e)}
                setConfirmPassword={(e) => setConfirmPassword(e)}
                setSearchLoca={(e) => setSearchLoca(e)}
                setInputLoca={setInputLoca}
                open={openEdit}
                handleClose={handleCloseEdit}
            />
        </>
    );
}

const MANAGEURL_TYPE = {
    0: { label: "menu_home", name: "หน้าหลัก", color: "var(--black)" },
    1: { label: "menu_stock", name: "ระบบคลังยา", color: "var(--black)" },
    2: {
        label: "menu_claim",
        name: "ระบบสนับสนุนการเคลม",
        color: "var(--black)",
    },
    3: { label: "menu_dashboard", name: "ระบบ Dashboard", color: "var(--black)" },
    4: {
        label: "menu_database",
        name: "ฐานข้อมูลประชากร",
        color: "var(--black)",
    },
    5: {
        label: "menu_financial",
        name: "ระบบรายรับ-รายจ่าย",
        color: "var(--black)",
    },
    6: {
        label: "menu_hr",
        name: "ทรัพยากรบุคคล",
        color: "var(--black)",
    },
};

export default CustomDrawer;
