import React from "react";
import { Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";

export default function Markerwhatever(props) {
     //  const map = useMap();
     const babyIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/7890/7890168.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const boyKidIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/4509/4509624.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const girlKidIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/4290/4290322.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const boyTeensIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/7013/7013630.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const girlTeensIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/7013/7013477.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const boyAdultIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/5615/5615699.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const girlAdultIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/3577/3577099.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const boyMenopauseIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/375/375248.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const girlMenopauseIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/2453/2453959.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const boyOldIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/6740/6740338.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });

     const girlOldIcon = new L.Icon({
          iconUrl: "https://cdn-icons-png.flaticon.com/512/2219/2219915.png",
          iconSize: [48, 48],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
     });
     const getIconByAge = (age, sex) => {
          if (age >= 0 && age <= 1) {
               return babyIcon;
          } else if (age > 1 && age <= 12) {
               if (sex === "ชาย") {
                    return boyKidIcon;
               }
               return girlKidIcon;
          } else if (age >= 13 && age <= 20) {
               if (sex === "ชาย") {
                    return boyTeensIcon;
               }
               return girlTeensIcon;
          } else if (age >= 21 && age <= 40) {
               if (sex === "ชาย") {
                    return boyAdultIcon;
               }
               return girlAdultIcon;
          } else if (age >= 41 && age < 60) {
               if (sex === "ชาย") {
                    return boyMenopauseIcon;
               }
               return girlMenopauseIcon;
          } else if (age >= 60) {
               if (sex === "ชาย") {
                    return boyOldIcon;
               }
               return girlOldIcon;
          } else {
               return "age error"; // กรณีที่อายุไม่ตรงกับเงื่อนไขใดๆ
          }
     };

     return (
          <MarkerClusterGroup>
               {props.filteredMarkers.map((marker, index) => (
                    <Marker key={index} position={marker.position} icon={getIconByAge(marker.age, marker.sex)}>
                         <Popup>
                              <div>
                                   <strong>{marker.title}</strong>
                                   <br />
                                   <strong>อายุ {marker.age} ปี {marker.age === 0 && String(marker.age_m) + ' เดือน'}</strong>
                                   <br />
                                   <strong>เพศ {marker.sex} </strong>
                                   <br />
                                   <strong>โรค {marker.detail} </strong>
                                   {/* <img src={marker.image} alt={marker.title} style={{ width: "100px", height: "auto" }} /> */}
                              </div>
                         </Popup>
                    </Marker>
               ))}
          </MarkerClusterGroup>
     );
}