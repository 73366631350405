import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";

import { StyledTblK103 } from "../../../Theme";

export default function GridTableHosp({ data, header, profile }) {
    // console.log("data:", data);
    let length = data.length;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((labelText) => (
                                <StyledTblK103 style={{ minWidth: 300, textAlign: "center" }}>{labelText.label}</StyledTblK103>
                            ))}
                        </TableRow>
                    </TableHead>
                    {profile.role_id === 1 || profile.role_id === 2 ? (
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                return (
                                    <TableRow style={{ backgroundColor: data.length === index + 1 ? "#eeeeee" : "#FAFAFA" }}>
                                        <StyledTblK103 align="left">{item.hname}</StyledTblK103>
                                        <StyledTblK103 align="center">{item.qty}</StyledTblK103>
                                        <StyledTblK103 align="center">{item.qty_status}</StyledTblK103>
                                        <StyledTblK103 align="center">{item.percent}</StyledTblK103>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    ) : (
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                                return (
                                    <TableRow style={{ backgroundColor: "#FAFAFA" }}>
                                        <StyledTblK103 align="left">{item.hname}</StyledTblK103>
                                        <StyledTblK103 align="center">{item.qty}</StyledTblK103>
                                        <StyledTblK103 align="center">{item.qty_status}</StyledTblK103>
                                        <StyledTblK103 align="center">{item.percent}</StyledTblK103>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    )}
                </Table>
                {data.length === 0 ? (
                    <Grid container justifyContent="center" my={3}>
                        <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
                ) : (
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100, 200]}
                        component="div"
                        count={length - 1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                    />
                )}
            </TableContainer>
        </Paper>
    );
}
