import { Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ClaimIcon, SendIcn } from "../../../../untils/icons";
import dayjs from "dayjs";
import { ButtonConfirm, ButtonTeal } from "../../../../Theme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TableList from "./TableList";
import { headerTable } from "../../../../untils/static";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

function ClaimCloseCard({
    dateUpdate,
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
    search,
    setSearch,
    setStatusSend,
    statusSend,
    data,
    handleChangeCheckbox,
    checkAll,
    handleSendClaim,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
}) {
    const [cleared, setCleared] = useState(false);
    const len = data.filter((e) => e.status === 1);

    var buddhistEra = require("dayjs/plugin/buddhistEra");
    dayjs.extend(buddhistEra);
    require("dayjs/locale/th");

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);
    return (
        <>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={12} mt={1} mb={3} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <ClaimIcon fontSize="medium" />
                                <Typography className="main-header">การยืนยันตัวตนหลังการรับบริการ(ปิดสิทธิ์)</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} className="flex-end">
                            <Typography>ข้อมูล ณ วันที่ {dayjs(dateUpdate).locale("th").format("D MMMM BBBB เวลา HH:mm น.")}</Typography>{" "}
                            {/* use API in this line here */}
                        </Grid>
                        <Grid item xs={12} className="flex-end">
                            <Typography sx={{ cursor: "pointer", mr: 2 }}>ส่งออกข้อมูล {len.length} รายการ</Typography>
                            {statusSend === 1 ? (
                                <ButtonConfirm
                                    onClick={() => handleSendClaim("authen")}
                                    sx={{ mr: 2, backgroundColor: "#f75454", "&:hover": { borderColor: "#166ABD", backgroundColor: "#f75454" } }}
                                >
                                    <CreditScoreIcon fontSize="small" />
                                    &nbsp;
                                    <Typography sx={{ cursor: "pointer" }}>ยกเลิกปิดสิทธิ์</Typography>
                                </ButtonConfirm>
                            ) : (
                                <ButtonConfirm
                                    disabled
                                    onClick={() => handleSendClaim("authen")}
                                    sx={{ mr: 2, backgroundColor: "#f75454", "&:hover": { borderColor: "#166ABD", backgroundColor: "#f75454" } }}
                                >
                                    <CreditScoreIcon fontSize="small" />
                                    &nbsp;
                                    <Typography sx={{ cursor: "pointer" }}>ยกเลิกปิดสิทธิ์</Typography>
                                </ButtonConfirm>
                            )}

                            {statusSend === 0 || statusSend === 2 ? (
                                <ButtonTeal /*disabled={type === 99}*/ onClick={handleSendClaim}>
                                    <SendIcn fontSize="small" />
                                    &nbsp;
                                    <Typography sx={{ cursor: "pointer" }}>ยืนยันปิดสิทธิ์</Typography>
                                </ButtonTeal>
                            ) : (
                                <ButtonTeal disabled onClick={handleSendClaim}>
                                    <SendIcn fontSize="small" />
                                    &nbsp;
                                    <Typography sx={{ cursor: "pointer" }}>ยืนยันปิดสิทธิ์</Typography>
                                </ButtonTeal>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} my={2}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={6} sm={6} md={3}>
                                    <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                        <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                            <Typography mt={2} color="var(--teal-A800)">
                                                จนถึง :
                                            </Typography>
                                        </Box>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                                <DatePicker
                                                    // fullWidth
                                                    value={dateStart}
                                                    slotProps={{
                                                        field: { clearable: true, onClear: () => setCleared(true) },
                                                    }}
                                                    label="วันที่เริ่มต้น"
                                                    format="DD/MM/YYYY"
                                                    onChange={(e) => setDateStart(e)}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3}>
                                    <Stack width="100%" direction={{ xs: "column", sm: "row", md: "row" }} spacing={1}>
                                        <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                            <Typography mt={2} color="var(--teal-A800)">
                                                จนถึง :
                                            </Typography>
                                        </Box>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                                <DatePicker
                                                    fullWidth
                                                    value={dateEnd}
                                                    slotProps={{
                                                        field: { clearable: true, onClear: () => setCleared(true) },
                                                    }}
                                                    label="วันที่สิ้นสุด"
                                                    format="DD/MM/YYYY"
                                                    onChange={(e) => setDateEnd(e)}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel>เลือกสถานะการปิดสิทธิ์</InputLabel>
                                        <Select
                                            name="เลือกสถานะการปิดสิทธิ์"
                                            value={statusSend}
                                            label="เลือกสถานะการปิดสิทธิ์"
                                            // displayEmpty
                                            required
                                            onChange={(e) => setStatusSend(e.target.value)}
                                        >
                                            {dataStatus_send &&
                                                dataStatus_send.map((el, i) => (
                                                    <MenuItem key={i + 1} value={el.value} disabled={el.disabled}>
                                                        {el.label}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="search"
                                label="ค้นหารายการ"
                                type="search"
                                variant="outlined"
                                size="medium"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mb={5}>
                            <TableList
                                header={headerTable.claim_close}
                                data={data}
                                handleChangeCheckbox={handleChangeCheckbox}
                                checkAll={checkAll}
                                statusSend={statusSend}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                        {/* <Grid item xs={12} className="flex-end">
                            <Typography sx={{ cursor: "pointer", mr: 2 }}>ส่งออกข้อมูล {len.length} รายการ</Typography>
                            <ButtonTeal disabled={type === 99} onClick={handleSendClaim}>
                                <SendIcn fontSize="small" />
                                &nbsp;
                                <Typography sx={{ cursor: "pointer" }}>ส่งออกข้อมูล</Typography>
                            </ButtonTeal>
                        </Grid> */}
                    </Grid>
                </Paper>
            </Box>
        </>
    );
}

export default ClaimCloseCard;

const dataStatus_send = [
    { label: "ทั้งหมด", value: 99 },
    { label: "รอปิดสิทธิ์", value: 0 },
    { label: "ผ่านการปิดสิทธิ์", value: 1 },
    { label: "ไม่ผ่านการปิดสิทธิ์", value: 2 },
    { label: "ข้อมูลซ้ำในระบบ", value: 3 },
    { label: "ยกเลิกปิดสิทธิ์", value: 4 },
    { label: "ผ่านการปิดสิทธิ์จาก HIS", value: 5 },
];
