import React, { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import { StyledTableCell } from "../../../Theme";
import { tofieds } from "../../../untils/shortcut";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";

export default function FinancialTable({
    headersso,
    headerinsso,
    statuswait,
    onConfirm,
    updateSelectedCount,
    selectAll,
    setSelectAll,
    selectedRows,
    setSelectedRows,
    sortedData,
    page,
    setPage
}) {
    const [openRows, setOpenRows] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const toggleRowOpen = (index) => {
        setOpenRows((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const handleSelectRow = (index) => {
        const newSelectedRows = { ...selectedRows, [index]: !selectedRows[index] };
        setSelectedRows(newSelectedRows);
        updateSelectedCount(newSelectedRows);
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        const newSelectedRows = !selectAll
            ? sortedData.reduce((acc, _, index) => {
                acc[index] = true;
                return acc;
            }, {})
            : {};
        setSelectedRows(newSelectedRows);
        updateSelectedCount(newSelectedRows);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const rows = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {statuswait === "2" || statuswait === "3" ? (
                                <StyledTableCell>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Checkbox
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            color="primary"
                                        />
                                        <Typography>ทั้งหมด</Typography>
                                    </Stack>
                                </StyledTableCell>
                            ) : null}
                            {(headersso || []).map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <React.Fragment>
                            {rows.length > 0 ? (
                                rows.map((row, index) => (
                                    <>
                                        <TableRow>
                                            {statuswait === "2" || statuswait === "3" ? (
                                                <StyledTableCell rowSpan={2}>
                                                    <Checkbox
                                                        checked={selectedRows[index] || false}
                                                        onChange={() => handleSelectRow(index)}
                                                    />
                                                </StyledTableCell>
                                            ) : null}

                                            <StyledTableCell rowSpan={2} >
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => toggleRowOpen(index)}
                                                >
                                                    {openRows[index] ? (
                                                        <KeyboardArrowUpIcon />
                                                    ) : (
                                                        <KeyboardArrowDownIcon />
                                                    )}
                                                </IconButton>
                                            </StyledTableCell>
                                            <StyledTableCell align="center" rowSpan={2} style={{ fontWeight: "bold" }}>
                                                {row.DataCredit.claim || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {dayjs(row.DataDebit.finance_date).format("DD MMM YYYY") || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.DataDebit.ref_doc || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.DataDebit.account_no || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.DataDebit.account_name || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {tofieds(row.DataDebit.debit) || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {tofieds(row.DataDebit.credit) || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.DataDebit.detail || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>

                                            {statuswait !== "2" && statuswait !== "3" && (
                                                <StyledTableCell align="center" rowSpan={2}>
                                                    <Box display="flex" justifyContent="center" gap={1}>
                                                        <Button
                                                            variant="contained"
                                                            color="success"
                                                            onClick={() => onConfirm(row)}
                                                        >
                                                            ยืนยัน
                                                        </Button>
                                                    </Box>
                                                </StyledTableCell>
                                            )}
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell align="center">
                                                {dayjs(row.DataCredit.finance_date).format("DD MMM YYYY") || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.DataCredit.ref_doc || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.DataCredit.account_no || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.DataCredit.account_name || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {tofieds(row.DataCredit.debit) || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {tofieds(row.DataCredit.credit) || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.DataCredit.detail || "ไม่มีรายละเอียด"}
                                            </StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headersso.length}>
                                                <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 1 }}>
                                                        <Typography variant="h6" gutterBottom component="div">
                                                            ข้อมูลเพิ่มเติม: <span style={{ fontSize: "14px" }}>{row.zipFileName || row.file_name}</span>
                                                        </Typography>
                                                        <Table size="small" aria-label="details">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {(headerinsso(row.DataCredit.claim) || []).map((column, i) => (
                                                                        <StyledTableCell key={i}>
                                                                            <Typography fontWeight="bold" fontSize="14px">
                                                                                {column.label}
                                                                            </Typography>
                                                                        </StyledTableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.DataCredit.alldata && row.DataCredit.alldata.length > 0 ? (
                                                                    row.DataCredit.alldata.map((item, id) => (
                                                                        <TableRow key={id}>
                                                                            {(headerinsso(row.DataCredit.claim) || []).map((column) => (
                                                                                <StyledTableCell key={column.id}>
                                                                                    {column.format ? column.format(item[column.id]) : item[column.id] || "ไม่มีรายละเอียด"}
                                                                                </StyledTableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    ))
                                                                ) : (
                                                                    <TableRow>
                                                                        <StyledTableCell
                                                                            colSpan={
                                                                                (headerinsso(row.DataCredit.claim) || []).length
                                                                            }
                                                                            align="center"
                                                                        >
                                                                            ไม่พบข้อมูล
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={headersso.length + (statuswait === "2" || statuswait === "3" ? 1 : 0)} align="center">
                                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                            ไม่พบข้อมูล
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={sortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Paper >
    );
}