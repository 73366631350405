import { Clear, Map } from "@mui/icons-material";
import { Autocomplete, Box, Container, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import MapChart from "./MapChart";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CircularProgress from "@mui/material/CircularProgress";

function GridCard({
    profile,
    data,
    setData,
    dataLocation,
    setProLocationName,
    dateEnd,
    dateStart,
    setDateStart,
    setDateEnd,
    subdistrictObj,
    loading,
    setHcode,
    proLocationName,
    home,
    setSelectedHome,
    selectedHome,
    setIdHome,
    getDataAfterZoom,
}) {
    // console.log("dataLocation:", dataLocation);
    // console.log("data:", data);
    const [cleared, setCleared] = useState(false);
    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
    }, [cleared]);

    return (
        <Container maxWidth="xxl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={5}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <Map fontSize="medium" sx={{ color: "#00695C" }} />
                                <Typography className="main-header">แผนที่ออกตรวจเยี่ยมบ้าน</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={12} sm={12} md={3}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                            <DatePicker
                                                value={dateStart}
                                                label="วันที่เริ่มต้น"
                                                format="DD/MM/YYYY"
                                                onChange={(e) => setDateStart(e)}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                            <DatePicker value={dateEnd} label="วันที่สิ้นสุด" format="DD/MM/YYYY" onChange={(e) => setDateEnd(e)} />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Autocomplete
                                        disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                        getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                        options={[{ hcode: "", hname: "ทั้งหมด" }, ...(dataLocation || [])]}
                                        onChange={(event, value) => {
                                            setProLocationName(value);
                                            if (!value) {
                                                return;
                                            }
                                            if (value) {
                                                setHcode(value.hcode);
                                            }
                                        }}
                                        value={proLocationName}
                                        noOptionsText="No data"
                                        renderInput={(params) => <TextField {...params} label="สถานพยาบาล" InputLabelProps={{ shrink: true }} />}
                                        clearOnEscape
                                        autoHighlight
                                        clearIcon={<Clear />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Autocomplete
                                        getOptionLabel={(option) => option.type}
                                        options={home}
                                        onChange={(event, value) => {
                                            setSelectedHome(value);
                                            if (value && value.id !== "") {
                                                setIdHome(value.id);
                                            } else {
                                                setIdHome("");
                                            }
                                        }}
                                        value={selectedHome}
                                        noOptionsText="No data"
                                        renderInput={(params) => (
                                            <TextField {...params} label="ถิ่นที่อยู่อาศัย" InputLabelProps={{ shrink: true }} />
                                        )}
                                        clearOnEscape
                                        autoHighlight
                                        disableClearable
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="flex-start" justifyContent="center">
                            {!loading ? (
                                <MapChart data={data} subdistrictObj={subdistrictObj} getDataAfterZoom={getDataAfterZoom} setData={setData} />
                            ) : (
                                <Box sx={{ display: "flex" }}>
                                    <CircularProgress />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default GridCard;
