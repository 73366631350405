import { Container } from "@mui/material";
import { debounce } from "@mui/material/utils";
import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { GET, GETROLE, GETUSERBYID, POST, SEARCHLOCATION, UPDATEUSER } from "../../service";
//calendar
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { getToken } from "../../untils/shortcut";
import GridTextField from "./components/GridTextField";

function EditUser() {
    const [dataScreen, setDataScreen] = useState([]);
    const [dataRole, setDataRole] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [location, setLocation] = useState("");
    const [IdCard, setIdCard] = useState("");
    const [roleId, setRoleId] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorIdCard, setErrorIdCard] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [searchHos, setSearchHos] = useState("");
    const [searchLoca, setSearchLoca] = useState("");
    const [inputLoca, setInputLoca] = useState("");

    const id_path = useLocation();
    const { detail } = id_path.state;

    const profile = getToken(localStorage.getItem("profile"));

    const getUserId = async () => {
        try {
            setLoading(true);
            let res = await POST(GETUSERBYID, { user_id: profile.user_id });
            if (res.success) {
                window.localStorage.removeItem("screen");
                window.localStorage.setItem("screen", JSON.stringify(res.result.screen_new));
                window.localStorage.removeItem("profile");
                window.localStorage.setItem("profile", JSON.stringify(res.result.token));
                window.location.replace("/User");
                setLoading(false);
            } else {
                setLoading(false);
                alert(res.result);
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const getUser = async () => {
        try {
            setLoading(true);
            let res = await POST(GETUSERBYID, { user_id: detail.user_id });
            if (res.success) {
                let data = [];
                let data_user = res.result;
                let data_screen = res.result.screen;
                setLocation(data_user.location_id);
                setName(data_user.name);
                setLastname(data_user.lastname);
                setIdCard(data_user.id_card);
                setPhone(data_user.phone);
                setEmail(data_user.email);
                setRoleId(data_user.role_id);
                setBirthDate(dayjs(data_user.birth_date));
                setSearchLoca(data_user.location_id !== null ? `${data_user.location_code} ${data_user.location_name}` : null);
                data_screen.map((e) =>
                    data.push({
                        ...e,
                        check: e.status === 1 ? true : false,
                    })
                );
                setDataScreen(data);
                setLoading(false);
            } else {
                setLoading(false);
                alert(res.result);
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const getRole = async () => {
        try {
            setLoading(true);
            let res = await GET(GETROLE);
            if (res.success) {
                let role = res.result;
                role.unshift({
                    role_name: "เลือกบทบาท ",
                    role_id: "",
                    disabled: true,
                });
                setDataRole(role);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const handleUpdateUser = async () => {
        // console.log(name, lastname, location, IdCard, roleId, phone, email, birthDate, dayjs(birthDate).format('YYYY-MM-DD HH:mm:ss'), password, confirmPassword);
        try {
            setLoading(true);
            let data = [];
            if (errorEmail || errorIdCard || errorPhone) {
                Swal.fire({
                    icon: "warning",
                    text: `กรุณาตรวจสอบข้อมูลให้ถูกต้อง`,
                    confirmButtonText: "ตกลง",
                });
            } else {
                if (password) {
                    if (confirmPassword === password) {
                        data.push({
                            name,
                            lastname,
                            location_id: location,
                            id_card: IdCard,
                            role_id: roleId,
                            phone,
                            email,
                            birth_date: dayjs(birthDate).format("YYYY-MM-DD HH:mm:ss"),
                            password,
                        });
                        let res = await POST(UPDATEUSER, { user_id: detail.user_id, data: data[0], screen: dataScreen });
                        if (res.success) {
                            setLoading(false);
                            Swal.fire({
                                icon: "success",
                                text: `แก้ไขบัญชีผู้ใช้งานสำเร็จ`,
                                confirmButtonText: "ตกลง",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    if (detail.user_id === profile.user_id) {
                                        getUserId();
                                    } else {
                                        window.location.replace("/User");
                                    }
                                }
                            });
                        } else {
                            setLoading(false);
                            Swal.fire({
                                icon: "warning",
                                text: `แก้ไขบัญชีผู้ใช้งานไม่สำเร็จ`,
                                confirmButtonText: "ตกลง",
                            });
                        }
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "error",
                            text: `รหัสผ่านไม่ตรงกัน`,
                            confirmButtonText: "ตกลง",
                        });
                    }
                } else {
                    data.push({
                        name,
                        lastname,
                        location_id: location,
                        id_card: IdCard,
                        role_id: roleId,
                        phone,
                        email,
                        birth_date: dayjs(birthDate).format("YYYY-MM-DD HH:mm:ss"),
                    });
                    let res = await POST(UPDATEUSER, { user_id: detail.user_id, data: data[0], screen: dataScreen });
                    if (res.success) {
                        setLoading(false);
                        Swal.fire({
                            icon: "success",
                            text: `แก้ไขบัญชีผู้ใช้งานสำเร็จ`,
                            confirmButtonText: "ตกลง",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (result.isConfirmed) {
                                    if (detail.user_id === profile.user_id) {
                                        getUserId();
                                    } else {
                                        window.location.replace("/User");
                                    }
                                }
                            }
                        });
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            text: `แก้ไขบัญชีผู้ใช้งานไม่สำเร็จ`,
                            confirmButtonText: "ตกลง",
                        });
                    }
                }
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const handleChangeText = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        if (name === "id_card") {
            const maxLength = 13;
            const regex = /^[0-9]\d*$/;
            value = e.target.value.replace(/[^0-9.]/g, "").substring(0, maxLength);
            if (value !== undefined && value.toString().length === maxLength && value.match(regex) && validNationalID(value)) {
                setErrorIdCard(false);
                return value;
            } else {
                setErrorIdCard(true);
            }
        }
        if (name === "phone") {
            const maxLength = 10;
            const regex = /(06|08|09)+[\d]{8}/;
            value = e.target.value.replace(/[^0-9.]/g, "").substring(0, maxLength);
            if (value !== undefined && value.toString().length === maxLength && value.match(regex)) {
                setErrorPhone(false);
                return value;
            } else {
                setErrorPhone(true);
            }
        }
        if (name === "email") {
            const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
            if (value !== undefined && value.match(regex)) {
                setErrorEmail(false);
                return value;
            } else {
                setErrorEmail(true);
            }
        }
        if (name === "role") {
            let index = dataRole.findIndex((x) => Number(x.role_id) === Number(value));
            dataRole[index].screen.map((e) =>
                setDataScreen((prevDataScreen) =>
                    prevDataScreen.map((item) =>
                        Number(item.screen_id) === Number(e.screen_id)
                            ? { ...item, check: e.status === 1 ? true : false, status: e.status === 1 ? true : false }
                            : item
                    )
                )
            );
            return value;
        }
        return value;
    };

    const validNationalID = (id) => {
        let i = 0;
        let sum = 0;
        if (id.length !== 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
            sum += parseInt(id.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check === parseInt(id.charAt(12))) {
            return true;
        }
        return false;
    };

    const handleChangeCheckbox = (event) => {
        let id = event.target.value;

        if (event.target.name === "ทั้งหมด") {
            // let data = []
            dataScreen.map((e) =>
                setDataScreen((prevDataScreen) =>
                    prevDataScreen.map((item) =>
                        Number(item.screen_id) === Number(e.screen_id)
                            ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 }
                            : item
                    )
                )
            );
            // setDataScreen(data)
        } else {
            setDataScreen((prevDataScreen) =>
                prevDataScreen.map((item) =>
                    Number(item.screen_id) === Number(id)
                        ? { ...item, check: event.target.checked, status: event.target.checked === true ? 1 : 0 }
                        : item
                )
            );
        }
    };

    const fetchLocation = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    let res = await POST(SEARCHLOCATION, { search: request });
                    if (res.success) {
                        setDataLocation(res.result.data);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: `ค้นหาไม่สำเร็จ`,
                            confirmButtonText: "ตกลง",
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: "warning",
                        text: `${error}`,
                        confirmButtonText: "ตกลง",
                    });
                }
            }, 400),
        []
    );

    // useEffect(() => {

    //     if (inputLoca === '') {
    //         setDataLocation(searchLoca ? [searchLoca] : []);
    //         return undefined;
    //     }

    //     fetchLocation(inputLoca);

    // }, [searchLoca, inputLoca, fetchLocation]);

    useEffect(() => {
        if (inputLoca.length > 3) {
            fetchLocation(inputLoca);
        } else if (!inputLoca) {
            fetchLocation(inputLoca);
            return undefined;
        }
    }, [searchLoca, inputLoca, fetchLocation]);

    useEffect(() => {
        getRole();
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <GridTextField
                action="update"
                dataScreen={dataScreen}
                dataRole={dataRole}
                dataLocation={dataLocation}
                handleChangeText={handleChangeText}
                handleUpdateUser={handleUpdateUser}
                handleChangeCheckbox={handleChangeCheckbox}
                errorIdCard={errorIdCard}
                errorPhone={errorPhone}
                errorEmail={errorEmail}
                birthDate={birthDate}
                roleId={roleId}
                location={location}
                name={name}
                lastname={lastname}
                id_card={IdCard}
                phone={phone}
                email={email}
                searchLoca={searchLoca}
                searchHos={searchHos}
                checkAll={dataScreen.findIndex((x) => Number(x.status) === 0)}
                setName={(e) => setName(e)}
                setLastname={(e) => setLastname(e)}
                setRoleId={(e) => setRoleId(e)}
                setLocation={(e) => setLocation(e)}
                setIdCard={(e) => setIdCard(e)}
                setPhone={(e) => setPhone(e)}
                setEmail={(e) => setEmail(e)}
                setBirthDate={(e) => setBirthDate(e)}
                setPassword={(e) => setPassword(e)}
                setConfirmPassword={(e) => setConfirmPassword(e)}
                setSearchHos={(e) => setSearchHos(e)}
                setSearchLoca={(e) => setSearchLoca(e)}
                setInputLoca={setInputLoca}
            />
        </Container>
    );
}

export default EditUser;
