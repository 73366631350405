import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import React from 'react'
import { CloseIcn } from '../../../untils/icons';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SearchIcon from "@mui/icons-material/Search";
import { ButtonTeal, StyledTableCell, StyledTblK103 } from '../../../Theme';

function DialogEdit({ setOpen, open, selectedOption, handleOptionChange, formRef, state, setState, birthDate, setBirthDate, handleGenderChange, genderOptions, home, setSelectedHome, selectedHome, ampur, tambon, handleSavecustom, handleSearchins, message, datains, handleSaveother }) {
    return (
        <>
            <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)} className="bg-dialog">
                <Box className="bg-gradiant">
                    {selectedOption === "custom" && (
                        <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)} className="bg-dialog">
                            <Box className="bg-gradiant">
                                <DialogActions className="flex-end">
                                    <CloseIcn className="close-icn" onClick={() => setOpen(false)} />
                                </DialogActions>
                                <DialogTitle className="text-header">เพิ่มประชากร</DialogTitle>
                                <Grid item xs={12} className="flex-center">
                                    <RadioGroup row value={selectedOption} onChange={handleOptionChange}>
                                        <FormControlLabel value="custom" control={<Radio />} label="กำหนดเอง" />
                                        <FormControlLabel value="other" control={<Radio />} label="อื่นๆ" />
                                    </RadioGroup>
                                </Grid>
                                <DialogContent className="flex-center">
                                    <form ref={formRef} className="flex-center">
                                        <DialogContentText className="container-bg" component={"span"}>
                                            <Grid>
                                                {/* <Grid item xs={12} className="flex-center">
                                  <Grid item xs={12}>
                                  <TextField
                                  required
                                  fullWidth
                                  variant="outlined"
                                  value={`${latlng.lat}, ${latlng.lng}`}
                                  />
                                  </Grid>
                                  </Grid> */}
                                                <Grid>
                                                    <DialogTitle style={{ color: "black" }}>ข้อมูลส่วนตัว</DialogTitle>
                                                </Grid>
                                                <Grid container spacing={1} item xs={12} className="flex-center">
                                                    {/* {console.log('state', state)} */}
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="ชื่อ - นามสกุล"
                                                            name="fullName"
                                                            placeholder="กรุณากรอกชื่อ-นามสกุล"
                                                            variant="outlined"
                                                            value={state["fullName"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="เลขบัตรประชาชน"
                                                            name="idCard"
                                                            placeholder="กรุณากรอกเลขบัตรประชาชน"
                                                            variant="outlined"
                                                            value={state["idCard"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                            inputProps={{ maxLength: 13 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(birthDate)}>
                                                                <DatePicker
                                                                    value={birthDate}
                                                                    label="วัน/เดือน/ปี เกิด"
                                                                    onChange={(e) => setBirthDate(e)}
                                                                />
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth variant="outlined" required>
                                                            <InputLabel>เพศ</InputLabel>
                                                            <Select value={state.sex || ""} onChange={handleGenderChange} label="เพศ">
                                                                {genderOptions.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="สัญชาติ"
                                                            name="nationDescription"
                                                            placeholder="กรุณากรอกสัญชาติ"
                                                            variant="outlined"
                                                            value={state["nationDescription"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid>
                                                    <DialogTitle style={{ color: "black" }}>ที่อยู่</DialogTitle>
                                                </Grid>
                                                <Grid container spacing={1} item xs={12} className="flex-center">
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Autocomplete
                                                            getOptionLabel={(option) => option.type}
                                                            options={home}
                                                            onChange={(event, value) => {
                                                                setSelectedHome(value);
                                                            }}
                                                            value={selectedHome}
                                                            noOptionsText="No data"
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="ถิ่นที่อยู่อาศัย"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            )}
                                                            clearOnEscape
                                                            autoHighlight
                                                            disableClearable
                                                        />
                                                    </Grid>
                                                    {/* {console.log('state', state)} */}
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="บ้านเลขที่"
                                                            name="house"
                                                            placeholder="กรุณากรอกบ้านเลขที่"
                                                            variant="outlined"
                                                            value={state["house"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="หมู่ที่"
                                                            name="village"
                                                            placeholder="กรุณากรอกหมู่บ้าน"
                                                            variant="outlined"
                                                            value={state["village"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="ซอย"
                                                            name="soimain"
                                                            placeholder="กรุณากรอกซอย"
                                                            variant="outlined"
                                                            value={state["soimain"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="ถนน"
                                                            name="road"
                                                            placeholder="กรุณากรอกถนน"
                                                            variant="outlined"
                                                            value={state["road"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="จังหวัด"
                                                            name="province"
                                                            placeholder="กรุณากรอกจังหวัด"
                                                            variant="outlined"
                                                            value={state["province"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>อำเภอ</InputLabel>
                                                            <Select
                                                                name="ampur"
                                                                value={state.ampur || ""}
                                                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                                label="อำเภอ"
                                                            >
                                                                {ampur.map((ampur) => (
                                                                    <MenuItem key={ampur.amp_th} value={ampur.amp_code}>
                                                                        {ampur.amp_th}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>ตำบล</InputLabel>
                                                            <Select
                                                                name="tambon"
                                                                value={state.tambon || ""}
                                                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                                label="ตำบล"
                                                            >
                                                                {tambon.map((tambon) => (
                                                                    <MenuItem key={tambon.tam_th} value={tambon.tam_code}>
                                                                        {tambon.tam_th}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Button
                                                sx={{ textAlign: "left", marginTop: "2%" }}
                                                variant="contained"
                                                color="success"
                                                onClick={handleSavecustom}
                                            >
                                                บันทึก
                                            </Button>
                                        </DialogContentText>
                                    </form>
                                </DialogContent>
                                {/* <DialogActions className="flex-center">
                              <ButtonTeal
                                sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                                onClick={() => {
                                  checkValidation();
                                }}
                              >
                                แก้ไขข้อมูล
                              </ButtonTeal>
                            </DialogActions> */}
                            </Box>
                        </Dialog>
                    )}

                    {selectedOption === "other" && (
                        <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)} className="bg-dialog">
                            <Box className="bg-gradiant">
                                <DialogActions className="flex-end">
                                    <CloseIcn className="close-icn" onClick={() => setOpen(false)} />
                                </DialogActions>
                                <DialogTitle className="text-header">เพิ่มประชากร</DialogTitle>
                                <Grid item xs={12} className="flex-center">
                                    <RadioGroup row value={selectedOption} onChange={handleOptionChange}>
                                        <FormControlLabel value="custom" control={<Radio />} label="กำหนดเอง" />
                                        <FormControlLabel value="other" control={<Radio />} label="อื่นๆ" />
                                    </RadioGroup>
                                </Grid>
                                <DialogContent className="flex-center">
                                    <form ref={formRef} className="flex-center">
                                        <DialogContentText mt={2} className="container-bg" component={"span"}>
                                            <Grid>
                                                {/* <Grid item xs={12} className="flex-center">
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          value={`${latlng.lat}, ${latlng.lng}`}
                        />
                      </Grid>
                    </Grid> */}
                                                <Grid container spacing={1} item xs={12} className="flex-center">
                                                    {/* {console.log('state', state)} */}
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="เลขบัตรประชาชน"
                                                            name="idCard"
                                                            placeholder="กรุณากรอกเลขบัตรประชาชน"
                                                            variant="outlined"
                                                            value={state["idCard"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                            inputProps={{ maxLength: 13 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ButtonTeal sx={{ width: "100%", height: "100%" }} onClick={handleSearchins}>
                                                            <SearchIcon />
                                                        </ButtonTeal>
                                                    </Grid>
                                                    {message && (
                                                        <strong style={{ marginTop: "1%", color: message.includes("สำเร็จ") ? "green" : "red" }}>
                                                            *{message}
                                                        </strong>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} className="flex-center">
                                                    <TableContainer sx={{ maxHeight: 800 }}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTblK103 align="center">หัวข้อ</StyledTblK103>
                                                                    <StyledTblK103 align="center">รายละเอียดข้อมูล</StyledTblK103>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">ชื่อ - นามสกุล</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.fullName ? datains.fullName : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">หมายเลขบัตร</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.pid ? datains.pid : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">วัน/เดือน/ปี เกิด</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.birthDate ? datains.birthDate : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">เพศ</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.sex ? datains.sex : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">อายุ</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.age ? datains.age : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">สัญชาติ</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.nationDescription ? datains.nationDescription : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">สิทธิการรักษา</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.mainInscl ? datains.mainInscl : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">หน่วยบริการปฐมภูมิ</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.hospSub
                                                                                ? datains.hospSub
                                                                                : datains.hospNew
                                                                                    ? datains.hospNew["hname"]
                                                                                    : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <StyledTableCell align="left">หน่วยบริการหลัก</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.hospMain
                                                                                ? datains.hospMain
                                                                                : datains.hospNew
                                                                                    ? datains.hospNew["hname"]
                                                                                    : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>

                                                                    <TableRow>
                                                                        <StyledTableCell align="left">จังหวัด</StyledTableCell>
                                                                        <StyledTableCell align="left">
                                                                            {datains.provinceName ? datains.provinceName : "N/A"}
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                </>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                                <Grid>
                                                    <DialogTitle style={{ color: "black" }}>ที่อยู่</DialogTitle>
                                                </Grid>
                                                <Grid container spacing={1} item xs={12} className="flex-center">
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Autocomplete
                                                            getOptionLabel={(option) => option.type}
                                                            options={home}
                                                            onChange={(event, value) => {
                                                                setSelectedHome(value);
                                                            }}
                                                            value={selectedHome}
                                                            noOptionsText="No data"
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="ถิ่นที่อยู่อาศัย"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            )}
                                                            clearOnEscape
                                                            autoHighlight
                                                            disableClearable
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="บ้านเลขที่"
                                                            name="house"
                                                            placeholder="กรุณากรอกบ้านเลขที่"
                                                            variant="outlined"
                                                            value={state["house"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="หมู่ที่"
                                                            name="village"
                                                            placeholder="กรุณากรอกหมู่บ้าน"
                                                            variant="outlined"
                                                            value={state["village"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="ซอย"
                                                            name="soimain"
                                                            placeholder="กรุณากรอกซอย"
                                                            variant="outlined"
                                                            value={state["soimain"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="ถนน"
                                                            name="road"
                                                            placeholder="กรุณากรอกถนน"
                                                            variant="outlined"
                                                            value={state["road"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="จังหวัด"
                                                            name="province"
                                                            placeholder="กรุณากรอกจังหวัด"
                                                            variant="outlined"
                                                            value={state["province"]}
                                                            onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>อำเภอ</InputLabel>
                                                            <Select
                                                                name="ampur"
                                                                value={state.ampur || ""}
                                                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                                label="อำเภอ"
                                                            >
                                                                {ampur.map((ampur) => (
                                                                    <MenuItem key={ampur.amp_th} value={ampur.amp_code}>
                                                                        {ampur.amp_th}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>ตำบล</InputLabel>
                                                            <Select
                                                                name="tambon"
                                                                value={state.tambon || ""}
                                                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                                                label="ตำบล"
                                                            >
                                                                {tambon.map((tambon) => (
                                                                    <MenuItem key={tambon.tam_th} value={tambon.tam_code}>
                                                                        {tambon.tam_th}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Button
                                                sx={{ textAlign: "left", marginTop: "2%" }}
                                                variant="contained"
                                                color="success"
                                                onClick={handleSaveother}
                                            >
                                                บันทึก
                                            </Button>
                                        </DialogContentText>
                                    </form>
                                </DialogContent>
                                {/* <DialogActions className="flex-center">
                  <ButtonTeal
                    sx={{ width: { xs: "200px", sm: "50%", md: "30%" } }}
                    onClick={() => {
                      checkValidation();
                    }}
                  >
                    แก้ไขข้อมูล
                  </ButtonTeal>
                </DialogActions> */}
                            </Box>
                        </Dialog>
                    )}
                </Box>
            </Dialog>
        </>
    )
}

export default DialogEdit