import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { GET, GETDATASTATEMENT, GETDATASTATEMENT2, POST_GIS, SELECTORHOSP,POST } from "../../service";
import { getToken } from "../../untils/shortcut";
import GridCard from "./DashboardStatement2/GridCard";

function DashboardStatement2() {
    const [dateStart, setDateStart] = useState(dayjs().startOf("year").utc(true));
    const [dateEnd, setDateEnd] = useState(dayjs());
    const [cleared, setCleared] = useState(false);
    const [dataStateMent, setDataStateMent] = useState([]);
    const [dataStateMent2, setDataStateMent2] = useState([]);
    const [select, setSelect] = useState([]);
    const [hname, setHname] = useState("");
    const [hcode, setHcode] = useState("");
    const [loading, setLoading] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));
    console.log("profile:", profile);

    const getDataStateMent = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATASTATEMENT, { monthstart: dateStart, monthend: dateEnd, hcode });
            if (res.success) {
                setDataStateMent(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const getDataStateMent2 = async () => {
        try {
            setLoading(true);
            let res = await POST(GETDATASTATEMENT2, { monthstart: dateStart, monthend: dateEnd, hcode });
            if (res.success) {
                setDataStateMent2(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    const selectHosp = async () => {
        try {
            setLoading(true);
            let res = await GET(SELECTORHOSP);
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    let fname = profile.location_code + " " + profile.location_name; // + กันได้ด้วยว่ะ
                    setHname(fname);
                    setHcode(profile.location_code);
                }
                setSelect(res.result);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataStateMent();
        getDataStateMent2();
        selectHosp();
    }, [dateStart, dateEnd, hcode]);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <>
            <GridCard
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                cleared={cleared}
                setCleared={setCleared}
                data={dataStateMent}
                data2={dataStateMent2}
                select={select}
                hname={hname}
                hcode={hcode}
                setHname={setHname}
                setHcode={setHcode}
            />
        </>
    );
}

export default DashboardStatement2;
