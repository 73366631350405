import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad.js";
import { GETPERSON, POST } from "../../service.js";
import { headerTable } from "../../untils/static.js";
import PersonCard from "./Person/PersonCard.js";

function Person() {
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [sendLineNotice, setSendLineNotice] = useState(false);
    const [idNumber, setIdNumber] = useState(null);
    const [text, setText] = useState(null);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [data, setData] = useState([]);
    const [lengthData, setLengthData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        setSendLineNotice(false);
        // await getPerson();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
    };

    const getPerson = async () => {
        try {
            setLoading(true);
            let res = await POST(GETPERSON, {
                rowperpage: rowsPerPage,
                page: page + 1,
                sendLineNotice,
                FName: fname,
                LName: lname,
                person_id: idNumber,
            });
            if (res.success) {
                setData(res.result.data);
                setLengthData(res.result.lengthData);
                setSendLineNotice(false);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "warning",
                text: error,
                icon: "warning",
                showConfirmButton: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = async () => {
        // if (idNumber && idNumber.length !== 13) {
        //     Swal.fire({
        //         icon: "warning",
        //         text: `กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก`,
        //         confirmButtonText: "ตกลง",
        //     });
        // }
        try {
            setLoadingBtn(true);
            let res = await POST(GETPERSON, {
                FName: fname ?? "",
                LName: lname ?? "",
                person_id: idNumber ?? "",
                rowperpage: rowsPerPage,
                page: 1,
                sendLineNotice,
            });
            // if ((res.success && text && text.trim() !== "") || (idNumber && idNumber.length === 13))
            if (res.success) {
                setPage(0);
                if (res.result) {
                    setData(res.result.data);
                    setLengthData(res.result.lengthData);
                } else {
                    setData([]);
                    setLengthData(0);
                }
                setSendLineNotice(true);
                setLoadingBtn(false);
            }
            // else if (!text || text.trim() === "") {
            //     Swal.fire({
            //         icon: "warning",
            //         text: "กรุณากรอกข้อมูลการค้นหา",
            //         confirmButtonText: "ตกลง",
            //     });
            //     setPage(0);
            //     setData([]);
            //     setLengthData(0);
            //     setLoadingBtn(false);
            // }
            else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
                setLoadingBtn(false);
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
            setLoadingBtn(false);
        } finally {
            setLoading(false);
        }
    };

    const handleReset = async () => {
        setFname("");
        setLname("");
        setIdNumber("");
        setLoadingBtn(false);
    };

    useEffect(() => {
        getPerson();
    }, [page, rowsPerPage]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <PersonCard
                    person_id={idNumber}
                    text={text}
                    fname={fname}
                    lname={lname}
                    setIdNumber={setIdNumber}
                    setText={setText}
                    setFname={setFname}
                    setLname={setLname}
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loadingBtn={loadingBtn}
                    data={data}
                    header={headerTable.person_header}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    lengthData={lengthData}
                />
            </Container>
        </>
    );
}

export default Person;
