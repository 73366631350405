import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BarChartHos from "./BarChartHos";
import BarChartVertical from "./BarChartVertical";
import ChartCaseOPD from "./ChartCaseOPD";
import ChartOPDClaim from "./ChartOPDClaim";
import DoughnutChart from "./DoughnutChart";
import { Dashboard } from "../../../untils/icons";
import ChartOPDClaimOfHosp from "./ChartOPDClaimofHosp";
import dayjs from "dayjs";
import { Autocomplete, Box, Container, FormControl, Grid, Paper, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "dayjs/locale/th";

dayjs.locale("th");

export default function GridCard({
    profile,
    dataLocation,
    searchLoca,
    setSearchLoca,
    setLocation,
    setInputLoca,
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
    setCleared,
    data,
    dataOPD,
    dataPerHosp,
    dataPerMonthNotPass,
    dataPerMonthAll,
    dataSent,
    OPDClaimByHosp,
    dataAmphur,
    amphur,
    setAmphur,
    setAmphurCode,
    auditPass,
    auditNotPass,
    loading,
}) {
    // console.log("dataLocation:", dataLocation);
    const [isLoading, setIsLoading] = useState(loading);

    const handleAmphurChange = (value) => {
        setAmphur(value);
        if (value) {
            setAmphurCode(value.ampurcode);
        }
        setIsLoading(true);
    };

    const handleLocationChange = (newValue) => {
        if (newValue) {
            setSearchLoca(newValue);
            setLocation(newValue.hcode);
        }
        setIsLoading(true);
    };

    const handleDateChange = (startDate, endDate) => {
        setDateStart(startDate);
        setDateEnd(endDate);
        setIsLoading(true);
    };

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);
    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={8} sm={6} md={6} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">Dashboard OPD</Typography>
                            </Stack>
                        </Grid>

                        {/* Select Data */}
                        <Grid item xs={12}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={12} sm={12} md={2}>
                                    <Autocomplete
                                        disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                        fullWidth
                                        getOptionLabel={(option) => (typeof option === "string" ? option : `${option.ampurname}`)}
                                        options={dataAmphur}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={amphur}
                                        noOptionsText="No Amphur"
                                        onChange={(event, value) => handleAmphurChange(value)}
                                        renderInput={(params) => <TextField {...params} label="อำเภอ" />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                    <Autocomplete
                                        disabled={(profile.role_id !== 1 && profile.role_id !== 2) || !amphur}
                                        fullWidth
                                        getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                        options={dataLocation}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={searchLoca}
                                        noOptionsText="No locations"
                                        onChange={(event, newValue) => handleLocationChange(newValue)}
                                        onInputChange={(event, newInputValue) => setInputLoca(newInputValue)}
                                        renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2.5}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                            <DatePicker
                                                value={dateStart}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่เริ่มต้น"
                                                onChange={(e) => handleDateChange(e, dateEnd)}
                                                views={["year", "month"]}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2.5}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                            <DatePicker
                                                value={dateEnd}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่สิ้นสุด"
                                                onChange={(e) => handleDateChange(dateStart, e)}
                                                views={["year", "month"]}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Chart */}
                        <Grid container spacing={2} mt={2}>
                            {/*จำนวนการเข้ารับบริการ*/}
                            <Grid item xs={12}>
                                <Box className="census-info-frame-header">
                                    <Typography className="text-info-header">จำนวนการเข้ารับบริการ</Typography>
                                </Box>
                                <Box className="census-info-frame">
                                    {isLoading ? (
                                        <Skeleton variant="rectangular" width="100%" height={400} />
                                    ) : (
                                        <DoughnutChart auditPass={auditPass} auditNotPass={auditNotPass} />
                                    )}
                                </Box>
                            </Grid>

                            {/*รายการที่ AUdit ไม่ผ่าน*/}
                            <Grid item xs={12}>
                                <Box className="census-info-frame-header">
                                    <Typography className="text-info-header">รายการที่ Audit ไม่ผ่าน</Typography>
                                </Box>
                                <Box className="census-info-frame">
                                    {isLoading ? (
                                        <Skeleton variant="rectangular" width="100%" height={400} />
                                    ) : (
                                        <BarChartVertical information={dataOPD} />
                                    )}
                                </Box>
                            </Grid>

                            {/* จำนวนการเข้ารับบริการแต่ละ รพ.สต. */}
                            <Grid item xs={12}>
                                <Box className="census-info-frame-header">
                                    <Typography className="text-info-header">จำนวนการเข้ารับบริการแต่ละ รพ.สต.</Typography>
                                </Box>
                                <Box className="census-info-frame">
                                    {loading ? (
                                        <Skeleton variant="rectangular" width="100%" height="500px" />
                                    ) : (
                                        <BarChartHos information={dataPerHosp} />
                                    )}
                                </Box>
                            </Grid>

                            {/* จำนวน OPD ที่ไม่ผ่าน */}
                            <Grid item xs={12}>
                                <Box className="census-info-frame-header">
                                    <Typography className="text-info-header">จำนวน OPD ที่ไม่ผ่าน</Typography>
                                </Box>
                                <Box className="census-info-frame">
                                    {loading ? (
                                        <Skeleton variant="rectangular" width="100%" height={400} />
                                    ) : (
                                        <ChartCaseOPD information={dataPerMonthNotPass} />
                                    )}
                                </Box>
                            </Grid>

                            {/* กราฟเป้าหมายการเคลม */}
                            <Grid item xs={12}>
                                <Box className="census-info-frame-header">
                                    <Typography className="text-info-header">กราฟเป้าหมายการเคลม</Typography>
                                </Box>
                                <Box className="census-info-frame">
                                    {loading ? (
                                        <Skeleton variant="rectangular" width="100%" height={400} />
                                    ) : (
                                        <ChartOPDClaim perMonthAll={dataPerMonthAll} dataSent={dataSent} />
                                    )}
                                </Box>
                            </Grid>

                            {/* จำนวน OPD ที่ส่งเคลมในระบบ */}
                            <Grid item xs={12}>
                                <Box className="census-info-frame-header">
                                    <Typography className="text-info-header">จำนวน OPD ที่ส่งเคลมในระบบ</Typography>
                                </Box>
                                <Box className="census-info-frame">
                                    {loading ? (
                                        <Skeleton variant="rectangular" width="100%" height={400} />
                                    ) : (
                                        <ChartOPDClaimOfHosp information={OPDClaimByHosp} />
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}
