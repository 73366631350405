import { Autocomplete, Box, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { MedIcon } from "../../../../untils/icons";
import { headerTable } from "../../../../untils/static";
import DrugTableList from "./DrugTableList";

function DrugCard({ dataDrug, profile, selectHosp, dataSelect, setDataSelect, setHcode }) {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={3}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <MedIcon fontSize="medium" />
                            <Typography className="main-header">รายการ Drug catalog</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                fullWidth
                                getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                options={selectHosp}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                value={dataSelect}
                                noOptionsText="No hname"
                                onChange={(event, newValue) => {
                                    setDataSelect(newValue);
                                    if (newValue) {
                                        setHcode(newValue.hcode);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="เลือกรพ.สต." />}
                            ></Autocomplete>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            label="ค้นหาชื่อยา / รหัสยา"
                            variant="outlined"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            size="medium"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <DrugTableList
                            header={headerTable.DrugCatalog}
                            header_admin={headerTable.DrugCatalog_admin}
                            searchTerm={searchTerm}
                            dataDrug={dataDrug}
                            profile={profile}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default DrugCard;
