import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { GET, GETMAPPOPULATION, POST_GIS, POSTMAP3, SELECTORHOSP } from "../../service";
import { getToken } from "../../untils/shortcut";
import GridCard from "./DashboardMapPopulation/GridCard";

function DashboardMapGIS() {
    const [loading, setLoading] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));
    const [hcode, setHcode] = useState("");
    const [data, setData] = useState([]);
    const [proLocationName, setProLocationName] = useState("ทั้งหมด");
    const [dataLocation, setDataLocation] = useState("");
    const [subdistrictObj, setSubdistrictObj] = useState([]);
    const [obj, setObj] = useState([]);
    const amountAll = obj.amountAll;
    const [home, setHome] = useState([{ id: "", type: "ทั้งหมด" }]);
    const [idHome, setIdHome] = useState("");
    const [selectedHome, setSelectedHome] = useState(home[0]);
    const [isPin, setIsPin] = useState("ทั้งหมด");
    // const [tamboncode, setTamboncode] = useState("");
    // const [currentZoom, setCurrentZoom] = useState("");
    // const [currentCenter, setCurrentCenter] = useState([]);
    // const [bounds, setBounds] = useState([]);

    const GetMapDashboard = async () => {
        try {
            setLoading(true);
            const res = await POST_GIS(GETMAPPOPULATION, { hcode, id: idHome, is_pin: isPin });
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setProLocationName(profile.location_name);
                    setHcode(profile.location_code);
                    setSubdistrictObj(res.result.countTambon.features);
                    setObj(res.result);
                    const apiData = res.result.data_type || [];
                    setHome([{ id: "", type: "ทั้งหมด" }, ...apiData]);
                } else {
                    setSubdistrictObj(res.result.countTambon.features);
                    setObj(res.result);
                    const apiData = res.result.data_type || [];
                    setHome([{ id: "", type: "ทั้งหมด" }, ...apiData]);
                }
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        } finally {
            setLoading(false);
        }
    };

    const getMapperson = async (data) => {
        try {
            const res = await POST_GIS(POSTMAP3, {
                hcode,
                id: idHome,
                is_pin: isPin,
                tamboncode: data[0],
                bounds: data[1],
                currentCenter: data[2],
            });
            if (res.success) {
                if (profile.role_id !== 1 && profile.role_id !== 2) {
                    setData(res.result.data);
                }
                setData(res.result.data);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        } finally {
            setLoading(false);
        }
    };

    const selectHospital = async () => {
        try {
            const res = await GET(SELECTORHOSP);
            if (res.success) {
                setDataLocation(res.result);
            }
        } catch (error) {
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    useEffect(() => {
        selectHospital();
    }, []);

    useEffect(() => {
        GetMapDashboard();
    }, [hcode, idHome, isPin]);

    return (
        <GridCard
            profile={profile}
            data={data}
            proLocationName={proLocationName}
            dataLocation={dataLocation}
            setProLocationName={setProLocationName}
            hcode={hcode}
            setHcode={(e) => setHcode(e)}
            subdistrictObj={subdistrictObj}
            amountAll={amountAll}
            loading={loading}
            home={home}
            isPin={isPin}
            setIsPin={(e) => setIsPin(e)}
            setHome={(e) => setHome(e)}
            setIdHome={(e) => setIdHome(e)}
            selectedHome={selectedHome}
            setSelectedHome={(e) => setSelectedHome(e)}
            getReloadMap={() => GetMapDashboard()}
            getZoomMap={(data) => getMapperson(data)}
        />
    );
}

export default DashboardMapGIS;
