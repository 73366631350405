import { Container } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GET, GETDATAK101, GETHNAMEK101, POST_GIS, SELECTORHOSP } from "../../service";
import { getToken } from "../../untils/shortcut";
import K101Card from "./K101/K101Card";

function K101() {
    const profile = getToken(localStorage.getItem("profile"));
    const year_length = new Date().getFullYear() - 1;
    const yearsData = Array.from({ length: 5 }, (_, index) => {
        return { value: year_length + index };
    });
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(dayjs().year());
    const [tablePregnant, setTablePregnant] = useState([]);
    const [tablePregnantTarget, setTablePregnantTarget] = useState([]);
    const [targetValue, setTargetValue] = useState([]);
    const [hname, setHname] = useState("");
    const [hcode, setHcode] = useState("ทั้งหมด");
    const [dataSelect, setDataSelect] = useState("ทั้งหมด");
    const [dataHname, setDataHname] = useState([]);

    const onSelect = async (name, value) => {
        // console.log("name:", name);
        // console.log("value:", value);
        if (name === "hcode") {
            setHcode(value);
        }
    };

    const getDataK101 = async () => {
        try {
            setLoading(true);
            let res = await POST_GIS(GETDATAK101, { year_quarter: year, hcode: hcode === "ทั้งหมด" ? "" : hcode });
            if (res.success) {
                setLoading(false);
                setTablePregnant(res.result.tablePregnant);
                setTablePregnantTarget(res.result.tablePregnantTarget);
                setTargetValue(res.result.target_value);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getHnameK101 = async () => {
        try {
            setLoading(true);
            // let res = await POST_GIS(GETHNAMEK101, { hname });
            let res = await GET(SELECTORHOSP);
            let hospname = profile.location_code + " " + profile.location_name;
            if (res.success) {
                if (profile.role_id === 1 || profile.role_id === 2) {
                    res.result.unshift("ทั้งหมด");
                } else {
                    setDataSelect(hospname);
                }
                setDataHname(res.result);
                setLoading(false);
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `ค้นหาไม่สำเร็จ`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDataK101();
    }, [year, hcode]);

    useEffect(() => {
        getHnameK101();
    }, []);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <K101Card
                profile={profile}
                yearsData={yearsData}
                year={year}
                setYear={setYear}
                tablePregnant={tablePregnant}
                tablePregnantTarget={tablePregnantTarget}
                targetValue={targetValue}
                hname={hname}
                hcode={hcode}
                dataHname={dataHname}
                dataSelect={dataSelect}
                setDataSelect={setDataSelect}
                setHname={setHname}
                setHcode={setHcode}
                onSelect={onSelect}
            />
        </Container>
    );
}

export default K101;
