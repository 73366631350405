import { Box, Card, CardContent, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { BtnLoading, BtnLoadingRed } from "../../../Theme.jsx";
import PersonTable from "./PersonTable.js";

function PersonCard({
    person_id,
    text,
    fname,
    lname,
    setText,
    setFname,
    setLname,
    setIdNumber,
    handleSearch,
    handleReset,
    loadingBtn,
    data,
    header,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    page,
    lengthData,
}) {
    const [isDataFetched, setIsDataFetched] = useState(false);

    const handleSearchClick = async () => {
        await handleSearch();
        setIsDataFetched(true); // ตั้งค่าสถานะเมื่อค้นหาข้อมูลสำเร็จ
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={3}>
                    <Grid item xs={12} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine">
                                <Stack spacing={2} width="100%">
                                    <Typography className="main-header">ฐานข้อมูลประชากร</Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} md={5}>
                                            <TextField
                                                fullWidth
                                                label="เลขบัตรประชาชน"
                                                name="number"
                                                type="tel"
                                                value={person_id}
                                                variant="outlined"
                                                onChange={(e) => setIdNumber(e.currentTarget.value)}
                                                inputProps={{ maxLength: 13 }}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} md={3.5}>
                                            <TextField
                                                fullWidth
                                                label="ชื่อ"
                                                name="fname"
                                                type="fname"
                                                value={fname}
                                                variant="outlined"
                                                onChange={(e) => setFname(e.currentTarget.value)}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} md={3.5}>
                                            <TextField
                                                fullWidth
                                                label="นามสกุล"
                                                name="lname"
                                                type="lname"
                                                value={lname}
                                                variant="outlined"
                                                onChange={(e) => setLname(e.currentTarget.value)}
                                            />
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2.5}>
                                            <BtnLoading fullWidth onClick={handleSearchClick} loading={loadingBtn} sx={{ p: 1 }}>
                                                ค้นหาข้อมูล
                                            </BtnLoading>
                                        </Grid>
                                        <Grid item my={2} xs={4} sm={2} md={2.5} ml={2}>
                                            <BtnLoadingRed fullWidth onClick={handleReset} loading={loadingBtn} sx={{ p: 1 }}>
                                                รีเซ็ต
                                            </BtnLoadingRed>
                                        </Grid>
                                    </Grid>
                                    {isDataFetched === false ? (
                                        <Grid container justifyContent="center" my={3}>
                                            <Typography noWrap>* กรุณากรอกเลขบัตรประชาชน หรือชื่อ-นามสกุล เพื่อตรวจสอบข้อมูล *</Typography>
                                        </Grid>
                                    ) : (
                                        <Grid>
                                            <PersonTable
                                                data={data}
                                                header={header}
                                                handleChangePage={handleChangePage}
                                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                lengthData={lengthData}
                                            />
                                        </Grid>
                                    )}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default PersonCard;
