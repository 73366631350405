import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";

import dayjs from "dayjs";
import { StyledTableCell } from "../../../Theme";
import { tofieds } from "../../../untils/shortcut";
// import { buddhistEra } from 'dayjs/plugin/buddhistEra'
import FileDownloadIcon from "@mui/icons-material/FileDownload";
export default function GridTableList({ header, data, year }) {
    var buddhistEra = require("dayjs/plugin/buddhistEra");
    dayjs.extend(buddhistEra);
    require("dayjs/locale/th");

    const handleRoute = (detail) => {
        return () => {
            window.open(`Statement/Details/${JSON.stringify({ file_name: detail.file_name, sub_funds: detail.sub_funds, year })}`, "_blank");
        };
    };

    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);

    // const handleChangePage = (event, newPage) => {
    //      setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //      setRowsPerPage(+event.target.value);
    //      setPage(0);
    // };
    const downloadPDF = (el) => {
        if (el.PAY) {
            window.open(`https://smt.nhso.go.th/smtf/api/budgetreport/budgetSummaryByVendorReportDetail/download?fileName=${el.PAY}`);
        } else if (el.WAIT) {
            window.open(`https://smt.nhso.go.th/smtf/api/budgetreport/budgetSummaryByVendorReportDetail/download?fileName=${el.WAIT}`);
        } else if (el.DEBT) {
            window.open(`https://smt.nhso.go.th/smtf/api/budgetreport/budgetSummaryByVendorReportDetail/download?fileName=${el.DEBT}`);
        } else {
            alert("!");
        }
    };
    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <StyledTableCell align="center">
                                            {row.date ? dayjs(row.date).locale("th").format("D MMM BBBB") : ""}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.log ? (
                                                <strong
                                                    style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
                                                    onClick={handleRoute(row)}
                                                >
                                                    {row.file_name}
                                                </strong>
                                            ) : (
                                                row.file_name
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.sub_funds}</StyledTableCell>
                                        <StyledTableCell align="right">{tofieds(row.amount)}</StyledTableCell>
                                        <StyledTableCell align="right">{tofieds(row.delay)}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <strong>{tofieds(row.bookbank)}</strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <FileDownloadIcon style={{ cursor: "pointer", color: "blue" }} onClick={() => downloadPDF(row)} />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                        {data.length > 0 && (
                            <TableRow style={{ backgroundColor: "#eeeeee" }}>
                                <StyledTableCell colSpan={3} align="left" style={{ fontWeight: "bold" }}>
                                    รวม
                                </StyledTableCell>
                                {/* <StyledTableCell />
                                        <StyledTableCell /> */}

                                <StyledTableCell align="right" style={{ fontWeight: "bold" }}>
                                    {tofieds(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.amount), 0))}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ fontWeight: "bold" }}>
                                    {tofieds(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.delay), 0))}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ fontWeight: "bold" }}>
                                    {tofieds(data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.bookbank), 0))}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ fontWeight: "bold" }}></StyledTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                data.length === 0 && (
                    <Grid container justifyContent="center" my={3}>
                        <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                    </Grid>
                )
                // :
                // <TablePagination
                //     rowsPerPageOptions={[100, 150, 200]}
                //     component="div"
                //     count={data.length}
                //     rowsPerPage={rowsPerPage}
                //     page={page}
                //     onPageChange={handleChangePage}
                //     onRowsPerPageChange={handleChangeRowsPerPage}
                //     labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                // />
            }
        </Paper>
    );
}
