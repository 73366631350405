// const localhost = "http://192.168.31.70:3333";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// const localhost = "http://103.138.179.148";
const localhost = process.env.REACT_APP_SERVICE;
// const localhost = "https://nh.projectsoft.co.th";
const localhost_gis = process.env.REACT_APP_SERVICE_GIS;

const path = localhost;
const path_gis = localhost_gis;
// const path = window.location.origin.includes("localhost") ? localhost : window.location.origin;
export const _ip = path;
export const ip = _ip + "/api";
export const _ip_gis = path_gis;
export const ip_gis = _ip_gis + "/api";

export const GET = (path) => {
    const token = localStorage.getItem("uuid");
    return new Promise((resolve, reject) => {
        fetch(ip + path, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token ? token : "token",
            },
            credentials: "include",
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.message === "Token Timeout" || json.message === "user not found") {
                    Swal.fire({
                        title: "warning",
                        text: `${json.message}`,
                        icon: "warning",
                        showConfirmButton: true,
                    }).then(() => {
                        localStorage.removeItem("profile");
                        localStorage.removeItem("screen");
                        localStorage.removeItem("timeOut");
                        window.location.assign("/Login");
                    });
                } else {
                    resolve(json);
                }
            })
            .catch((err) => {
                console.log("err:", err);
                reject(err);
            });
    });
};

export const POST = (path, obj, formData) => {
    const token = localStorage.getItem("uuid");

    // let lang = "th";
    // console.log("path", path);
    // console.log("ip", ip);
    return new Promise((resolve, reject) => {
        fetch(ip + path, {
            method: "POST",
            headers: formData
                ? {}
                : {
                      "Content-Type": "application/json",
                      Authorization: token ? token : "token",
                  },
            body: formData ? obj : JSON.stringify({ ...obj }),
            credentials: "include",
        })
            .then((response) => response.json())
            .then((json) => resolve(json))
            .catch((err) => reject(err));
    });
};

//GET GIS
export const GET_GIS = (path) => {
    const token = localStorage.getItem("uuid");
    return new Promise((resolve, reject) => {
        fetch(ip_gis + path, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token ? token : "token",
            },
            credentials: "include",
        })
            .then((response) => response.json())
            .then((json) => resolve(json))
            .catch((err) => reject(err));
    });
};

//POST MAP_GIS
export const POST_GIS = (path, obj, formData) => {
    const token = localStorage.getItem("uuid");

    return new Promise((resolve, reject) => {
        fetch(ip_gis + path, {
            method: "POST",
            headers: formData
                ? {}
                : {
                      "Content-Type": "application/json",
                      Authorization: token ? token : "token",
                  },
            body: formData ? obj : JSON.stringify({ ...obj }),
            credentials: "include",
        })
            .then((response) => response.json())
            .then((json) => resolve(json))
            .catch((err) => reject(err));
    });
};

//LOGIN
export const LOGIN = "/auth/login";
export const LOGOUT = "/auth/logout";

export const VERIFY = "/line/verify_identity";

//USER
export const GETUSERALL = "/user/get_user_all";
export const GETUSERBYID = "/user/get_user_id";
export const DELETEUSER = "/user/delete_user";
export const ADDUSER = "/user/create_user";
export const UPDATEUSER = "/user/update_user";
export const UPDATESTATUSUSER = "/user/update_user_by";
export const GETROLE = "/user/get_role_all";
export const GETLOCATION = "/user/get_location_all";
export const GETHOSPITAL = "/user/get_hospital_all";
export const GETVENDORALL = "/user/get_vendor_all";
export const GETSCREEN = "/user/get_screen_all";
export const SEARCHHOSPITAL = "/user/search_hospital";
export const SEARCHLOCATION = "/user/search_location";
export const GETDASHBOARD = "/user/get_dashboard";
export const GETDASHBOARDBYID = "/user/get_dashboard_by";
export const SEARCHUSER = "/user/search_user";

//ROLE
export const GETMANAGEROLE = "/role/get_role";
export const GETROLEBYID = "/role/get_role_id";
export const ADDROLE = "/role/create_role";
export const UPDATEROLE = "/role/update_role";
export const DELETEROLE = "/role/update_data_role";

//GRUG
export const GETDRUGALL = "/drug/get_drug_all";
export const DELETEDRUG = "/drug/delete_drug";
export const ADDDRUG = "/drug/create_drug";
export const UPDATEDRUG = "/drug/update_drug";
export const GETDRUGBYID = "/drug/get_drug_id";
export const SEARCH = "/drug/search_drug";
export const SEARCHDRUGTYPE = "/drug/search_drug_type";
export const GETDRUGSTOCK = "/drug/get_cdrug_all";
export const GETSEARCHDRUGSTOCK = "/drug/search_cdrug";

//TRANSACTION
export const ADDTRANSACTION = "/transaction/create_transaction";
export const GETTRANSACTIONALL = "/transaction/get_order_all";
export const GETTRANSACTIONBYID = "/transaction/get_order_by";
export const APPROVETRANSITION = "/transaction/approve_order_by";

//DEVICE
export const GETDEVICE = "/device/get_device_all";
export const UPDATEDEVICE = "/device/update_device";
export const SEARCHDEVICE = "/device/search_device";
export const DELETEDEVICE = "/device/delete_device";

//Stock
export const GETSTOCK = "/stock/get_stock_all";
export const SEARCHSTOCK = "/stock/search_stock";

//Claim
export const GETCLAIM = "/opd/get_opd_all";
export const GETCLAIMDETAIL = "/opd/get_opd_by";
export const GETCLAIMVACCINE = "/opd/get_vin_all";
export const SENDCLAIM1 = "/claim/eclaim_option_1";
export const SENDCLAIM2 = "/claim/eclaim_option_2";
export const SENDCLAIMVACCINE = "/claim/m_claim_by";
export const GETTOKENECLAIM = "/token/get_token_eclaim";
export const GETTOKENMCLAIM = "/token/get_token_mclaim";
export const UPDATELOCATIONBY = "/user/update_location_by";
export const GETLOCATIONBY = "/user/get_location_by";
export const GETCLAIMTYPE = "/claim/get_claim_type";
export const GETBIRTHCONTROL = "/opd/get_birth_control_all";
export const GETBIRTHCONTROLBY = "/opd/get_birth_control_by";
export const ADDBIRTHCONTROL = "/opd/create_birth_control";
export const UPDATEBIRTHCONTROL = "/opd/update_birth_control";
export const GETHERB = "/opd/get_herb_all";
export const GETHERBBY = "/opd/get_herb_by";
export const ADDHERB = "/opd/create_herb";
export const UPDATEHERB = "/opd/update_herb";
export const GETHERBMASTER = "/opd/get_herb_master";
export const GETBIRTHCONTROLMASTER = "/opd/get_birth_master";
export const GETPREGNANT = "/opd/get_pregnant_all";
export const GETPREGNANTBY = "/opd/get_pregnant_by";
export const ADDPREGNANT = "/opd/create_pregnant";
export const GETPREGNANTMASTER = "/opd/get_pregnant_master";
export const UPDATEPREGNANT = "/opd/update_pregnant";
export const LASTDTUPDATE = "/opd/get_last_dt_update";
export const GETKIOSK = "/kiosk";
export const CHECKKIOSK = "/kiosk/check_kiosk_token";
export const CHECKAUTH = "/kiosk/check_auth_token";
export const KIOSKSAVE = "/kiosk/save";
export const GETSTATUSVACCINE = "/dashboard/get_claim_vaccine";
export const GETDMHTALL = "/opd/get_dmht_all";
export const CHECKSSS = "/token/check_sss";
export const GETCLAIMCLOSE = "/opd/get_claim_close";
export const SENDCLAIMCLOSE = "/claim/claim_close";
export const SENDCANCELCLAIMCLOSE = "/claim/cancel_claim_close";
export const CHECKSEAMLESS = "/token/check_user_seamless";
export const CHECKHTK = "/token/check_user_health_platform_ktb";
export const CHECKNEWAUTHCODE = "/token/check_authenservice";
export const SENDCLAIMSSO = "/claim/claim_kru_sso";
export const SENDCLAIMBOTSSO = "/claim/check_kru_sso";

export const DELETEBIRTHCONTROL = "";
export const DELETEHERB = "/drug/delete_herb";
export const DELETECONTRACEPTIVE = "/drug/delete_contraceptive_pill";
export const DELETEPREGNANT = "/drug/delete_pregnant_drug";

export const GETDATADRUGCATALOG = "/claim/drug_category_data";

//Dashboard
export const GETDASHBOARDDATA = "/dashboard/dashboard16";
export const SELECTDASHBOARD16 = "/selector/selector_dashboard16";
export const GETMAP16 = "/dashboard/dashboard16_map";
export const GETTAMBON = "/dashboard/tambon";
export const GETSTATEMENT = "/claim/get_statement_eclaim";
export const GETCHARTSTATEMENT = "/claim/get_chart_eclaim";
export const DASHBOARDPAYMENT = "/dashboard/payment";
export const DASHBOARDK103 = "/dashboard/k103";
export const DASHBOARDSELECTDATA = "/dashboard/select_hname_K103";
export const SELECTDATAHYPER = "/dashboard/select_hname_k111_hypertension";
export const SELECTDATADIABETES = "/dashboard/select_hname_k111_diabetes";
export const GETDATAPHYPER = "/dashboard/k111_hypertension";
export const GETDATADIABETES = "/dashboard/k111_diabetes";
export const GETDATAK101 = "/dashboard/k101";
export const GETHNAMEK101 = "/dashboard/select_hname_K101";
export const GETAMPHUR = "/dashboard/select_amphur_hcode";
export const GETDATANOTPASSCLAIM = "/dashboard/opd_claim_rate";
export const GETDATANOTPASSCLAIMSUB = "/dashboard/opd_claim_rate_list";
export const GETDATASTATEMENT = "/statement/hsub-response";
export const GETDATASTATEMENT2 = "/statement/uc-response";
export const GETSTATEMENTVACCINE = "/dashboard/statement_vaccine";

//Select hospital
export const SELECTORHOSP = "/selector/hospital";

//Select Map Person
export const SELECTORMAPPERSON = "/selector/selector_map_person";

//Map Population
export const GETMAPPOPULATION = "/gis/map_person_count";

//DashboardOPD
export const GETOPD = "/dashboard/opd";
export const GETNAMEPREAUDIT = "/dashboard/opd_name_preAudit";
export const GETGRAPHPERHOSP = "/dashboard/opd_graph_per_hosp";
export const GETGRAPHPERMONTH = "/dashboard/opd_graph_per_month";
export const GETCLAIMOFHOSP = "/dashboard/opd_claim_of_hosp";

//MAP GIS
export const POSTMAP = "/gis/map_chronic";
export const POSTMAP2 = "/gis/map_diagnosis";
export const POSTMAP3 = "/gis/map_person";
export const DATAMAPPOPULATION = "/gis/map_person_data";
export const SEARCHADDRESS = "/gis/search_address";
export const CREATEPERSON = "/gis/create_person";
export const CHRONIC = "/gis/chronic";

//ManageUrl
export const MANAGEURL = "/pageurl/screen";
export const ADD_MANAGEURL = "/pageurl/add_screen";
export const UPDATE_MANAGEURL = "/pageurl/update_screen";
export const ADDTYPE_MANAGEURL = "/pageurl/add_type";
export const GETTYPE = "/pageurl/type";

//ManageClaim
export const GETMANAGECLAIM = "/manageclaim/get_claim_type";
export const EDITMANAGECLAIM = "/manageclaim/edit_claim_type";
export const EDITSORTMANAGECLAIM = "/manageclaim/edit_sort_claim_type";

//OTP
export const OTPCONFIG = "/otp/config";
export const OTPSAVE = "/otp/save";
export const GETOTP = "/otp/get_otp";
export const OTPMASTER = "/otp/";
export const OTPVERIFY = "/otp/verify";
export const RESENDOTP = "/otp/resend";

//ReportDeath
export const REPORTDEATH = "/dashboard/death_report";
export const GETMAPREPORTDEATH = "/dashboard/death_report_map";

//ReportDiagnose
export const REPORTDIAGNOSE = "/dashboard/diagnose_report";
export const REPORTDIAGNOSEMAP = "/dashboard/diagnose_report_map";

//Person
export const GETPERSON = "/person/search_person";
export const SEARCHINSCL = "/person/search_inscl";

//HospitalStatus
export const GETHOSPITALSTATUS = "/monitor/hospital_status";
export const GENCSVHOSPITALSTATUS = "/monitor/download_csv_hospitalStatus";

//Sso
export const GETDATASSO = "/claim/social_security";

//FinancialStatement
export const INANCIAL = "/account/get_inancial";
export const MOPH = "/account/get_moph";
export const SSO = "/account/get_sso";
export const LGO = "/account/get_lgo";
export const ELCAIM = "/account/get_elcaim";
export const ELCAIMSTATE = "/account/get_elcaim_state";
export const GETDETAILFINANCIAL = "/account/get_recording";
export const GETDATAFINANCIAL = "/account/get_recording_by_date";
export const INSERTDATAFINANCIAL = "/account/insert_recording";
export const UPDATEDATAFINANCIAL = "/account/update_recording";
export const DELETEFINANCIAL = "/account/delete_data";
export const SEARCHFINANCIAL = "/account/search_recording";
export const AUTOCOMPLETEFINANCIAL = "/account/get_auto_complete";
export const GENEXCELSTATEMENT = "/account/download_excel_recording";
export const GENPDFSTATEMENT = "/account/gen_pdf_recording";

//FinancialSum
export const GETDATAFINANCIALSUM = "/account/get_bringforward";
export const INSERTFINANCIALSUM = "/account/insert_bringforward";
export const GENPDFSUM = "/account/gen_pdf";
export const GENEXCELSUM = "/account/download_excel_bring_forward";

//ManageType
export const GETDATATYPE = "/pagetype/type";
export const ADDTYPE = "/pagetype/add_type";
export const UPDATETYPE = "/pagetype/update_type";
export const DELETETYPE = "/pagetype/remove_type";

//HumanResources
export const GETDATAHR = "/hr/";
export const ADDHR = "/hr/insert_data_hr";
export const UPDATEHR = "/hr/update_data_hr";
export const DELETEHR = "/hr/delete_data_hr";
export const SELECTORHR = "/selector/pos_lv_numpos";
export const GETDATAHRTBL = "/hr/hr_data_table";
export const SELECTAMP = "/selector/hospital_by_amphur";

//MonitorInputData
export const GETDATAMONITORINPUT = "/monitor/";

//Monitor2
export const GETDATAMONITOR2 = "";
