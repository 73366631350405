import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/th';
import { headerTable } from "../../../untils/static";
import FinalcialTable from "./FinancialTable";
import { BtnConfirmClaim } from "../../../Theme";
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import PaymentIcon from '@mui/icons-material/Payment';

dayjs.locale('th');

function FinancialList({
    data,
    status,
    setStatus,
    statuswait,
    setStatusWait,
    datestart,
    dateend,
    setDateStart,
    setDateEnd,
    onConfirm,
    onCancel,
    setSelectedCount,
    selectedRows,
    setSelectedRows,
    selectedCount,
    selectAll,
    setSelectAll,
    sortedData,
    page,
    setPage
}) {

    const handleSelectedRowsChange = (newSelectedRows) => {
        setSelectedRows(newSelectedRows);
    };

    const handleSelectAllChange = (newSelectAll) => {
        setSelectAll(newSelectAll);
    };

    const updateSelectedCount = (newSelectedRows) => {
        const selectedCount = Object.values(newSelectedRows).filter((isSelected) => isSelected).length;
        setSelectedCount(selectedCount);
        setSelectedRows(newSelectedRows);
    };

    const maxDateEnd = datestart.add(3, 'month').isBefore(dayjs())
        ? datestart.add(3, 'month')
        : dayjs();

    const headersso = () => {
        if (statuswait === "2" || statuswait === "3") {
            return headerTable.FinancialStatementheader2 || [];
        }
        return headerTable.FinancialStatementheader1 || [];
    };


    const headerinsso = (type) => {
        switch (type) {
            case "SSO":
                return headerTable.FinancialStatementSSO;
            case "LGO":
                return headerTable.FinancialStatementLGO;
            case "ELCAIM":
                return headerTable.FinancialStatementECLAIM;
            case "MOPH":
                return headerTable.FinancialStatementMOPH;
            default:
                return [];
        }
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} mt={1} mb={3} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <PaymentIcon sx={{ color: "var(--teal-A800)" }} fontSize="medium" />
                            <Typography
                                className="main-header"
                                style={{ textDecoration: "underline", cursor: "pointer" }}
                            >
                                ตั้งลูกหนี้
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <Grid container columns={12} spacing={1} mb={2} className="flex-start">
                            <Grid item xs={12} sm={12} md={2}>
                                <FormControl fullWidth>
                                    <InputLabel>เลือกสิทธิ</InputLabel>
                                    <Select
                                        name=""
                                        value={status}
                                        label="เลือกสิทธิ"
                                        // displayEmpty
                                        required
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <MenuItem value="0">ทั้งหมด</MenuItem>
                                        <MenuItem value="1">SSO</MenuItem>
                                        <MenuItem value="2">LGO</MenuItem>
                                        <MenuItem value="3">ELCAIM</MenuItem>
                                        {/* <MenuItem value="5">MOPH</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                                            <DatePicker
                                                label="วันเริ่มต้น"
                                                value={datestart}
                                                onChange={(newValue) => {
                                                    setDateStart(newValue);
                                                    const newDateEnd = newValue.add(3, 'month');
                                                    setDateEnd(newDateEnd.isAfter(dayjs()) ? dayjs() : newDateEnd);
                                                }}
                                                format="DD MMM YYYY"
                                                maxDate={dayjs()}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                                            <DatePicker
                                                label="วันสิ้นสุด"
                                                value={dateend}
                                                onChange={(newValue) => setDateEnd(newValue)}
                                                format="DD MMM YYYY"
                                                maxDate={maxDateEnd}
                                                minDate={datestart}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <FormControl fullWidth>
                                    <InputLabel>เลือกสถานะ</InputLabel>
                                    <Select
                                        name=""
                                        value={statuswait}
                                        label="เลือกสถานะ"
                                        required
                                        onChange={(e) => setStatusWait(e.target.value)}
                                    >
                                        <MenuItem value="1">รอตรวจสอบ</MenuItem>
                                        <MenuItem value="2">ตรวจสอบสำเร็จ</MenuItem>
                                        <MenuItem value="3">ยกเลิกการตรวจสอบ</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="flex-end">
                            <Typography sx={{ cursor: "pointer", mr: 2 }}>ข้อมูล {selectedCount} รายการ</Typography>
                            <BtnConfirmClaim
                                onClick={onCancel}
                                disabled={selectedCount === 0}
                                sx={{
                                    opacity: selectedCount === 0 ? 0.5 : 1,
                                    cursor: selectedCount === 0 ? "not-allowed" : "pointer",
                                }}
                            >
                                <CreditCardOffIcon fontSize="small" />
                                &nbsp;
                                <Typography>ยกเลิก</Typography>
                            </BtnConfirmClaim>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} mb={2}>
                    <FinalcialTable
                        headersso={headersso()}
                        headerinsso={headerinsso}
                        data={data}
                        statuswait={statuswait}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        updateSelectedCount={updateSelectedCount}
                        handleSelectedRowsChange={handleSelectedRowsChange}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectAll={selectAll}
                        setSelectAll={handleSelectAllChange}
                        sortedData={sortedData}
                        page={page}
                        setPage={setPage}
                    />
                </Grid>
            </Paper>
        </Box>
    );
}

export default FinancialList;
