import { Container, debounce } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { GETLOCATION, GETLOCATIONBY, GETUSERBYID, POST, SEARCHLOCATION, UPDATELOCATIONBY } from "../../service";

import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { getToken } from "../../untils/shortcut";
import DialogEdit from "./ListLocation/DialogEdit";
import ListLocation from "./ListLocation/ListLocation";

function Location() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [name, setName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [option, setOption] = useState("");
    const [openEdit, setOpenEdit] = useState(false);

    const profile = getToken(localStorage.getItem("profile"));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getLocation = async () => {
        try {
            setLoading(true);
            let res = await POST(GETLOCATION, { rowperpage: page * rowsPerPage + rowsPerPage });
            if (res.success) {
                setData(res.result.data);
                setCount(res.result.count);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getLocationId = async (id) => {
        try {
            let res = await POST(GETLOCATIONBY, { location_id: id });
            if (res.success) {
                setName(res.result.location_name);
                setLocationId(res.result.location_id);
                if (res.result.option_claim) {
                    setOption(res.result.option_claim);
                }
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const getUserId = async () => {
        try {
            setLoading(true);
            let res = await POST(GETUSERBYID, { user_id: profile.user_id });
            if (res.success) {
                // let data_screen = res.result.screen.filter((e) => e.status === 1);
                window.localStorage.removeItem("screen");
                window.localStorage.setItem("screen", JSON.stringify(res.result.screen_new));
                window.localStorage.removeItem("profile");
                window.localStorage.setItem("profile", JSON.stringify(res.result.token));
                window.location.replace("/Location");
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const handleUpdate = async (id) => {
        // console.log(id, name, option,profile);

        try {
            setLoading(true);

            let data = [];

            data.push({
                location_name: name,
                option_claim: option,
            });

            let res = await POST(UPDATELOCATIONBY, { location_id: id, data: data[0] });
            if (res.success) {
                setLoading(false);
                Swal.fire({
                    icon: "success",
                    text: `แก้ไขข้อมูลสำเร็จ`,
                    confirmButtonText: "ตกลง",
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (id === profile.location_id) {
                            getUserId();
                        } else {
                            window.location.replace("/Location");
                        }
                    }
                });
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    // console.log({ search, request });
                    // setLoading(true);
                    const payload = {
                        search: request,
                    };
                    let res = await POST(SEARCHLOCATION, payload);
                    if (res.success) {
                        setData(res.result.data);
                        setCount(res.result.count);
                        setPage(0);
                        // setLoading(false)
                    } else {
                        // setLoading(false)
                        Swal.fire({
                            icon: "warning",
                            text: `${res.message}`,
                            showConfirmButton: true,
                        });
                    }
                } catch (error) {
                    // setLoading(false)
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 1500), //หน่วง
        []
    );

    const handleClickOpenEdit = (id) => {
        //call api get data
        // console.log(code);
        getLocationId(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setName("");
        setOption("");
        setLocationId("");
        setOpenEdit(false);
    };

    useEffect(() => {
        if (search.length < 2) {
            getLocation();
            // setPage(0);
            return undefined;
        } else if (!search) {
            getLocation();
            return undefined;
        }
        fetch(search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, fetch, page, rowsPerPage]);

    // useEffect(() => {
    //     if (!search)
    //         getLocation()
    // }, [page, rowsPerPage]);

    return (
        <>
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <BackdropLoad loading={loading} />
                <ListLocation
                    data={data}
                    setData={setData}
                    search={search}
                    setSearch={setSearch}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleClickOpenEdit={handleClickOpenEdit}
                />
            </Container>
            <DialogEdit
                open={openEdit}
                handleClose={handleCloseEdit}
                setName={setName}
                name={name}
                setOption={setOption}
                option={option}
                handleUpdate={handleUpdate}
                setLocationId={setLocationId}
                locationId={locationId}
            />
        </>
    );
}

export default Location;
