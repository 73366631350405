import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { StyledTableCell } from "../../../../Theme";

// import { buddhistEra } from 'dayjs/plugin/buddhistEra'
export default function DrugTableList({ profile, dataDrug, header, header_admin, searchTerm }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filteredData =
        dataDrug && dataDrug.length > 0
            ? dataDrug.filter(
                  (drug) =>
                      (drug.genericname && drug.genericname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                      (drug.hospdrugcode && drug.hospdrugcode.toLowerCase().includes(searchTerm.toLowerCase())) ||
                      (drug.ndc24 && drug.ndc24.toLowerCase().includes(searchTerm.toLowerCase()))
              )
            : [];

    return (
        <Paper sx={{ width: "100%" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {profile.role_id === 1 || profile.role_id === 2
                                ? header_admin.map((column) => (
                                      <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                          {column.label}
                                      </StyledTableCell>
                                  ))
                                : header.map((column) => (
                                      <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                          {column.label}
                                      </StyledTableCell>
                                  ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {profile.role_id === 1 || profile.role_id === 2
                            ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                  return (
                                      <TableRow key={index + 1}>
                                          <StyledTableCell align="left">
                                              {row.hcode} {row.hname}
                                          </StyledTableCell>
                                          <StyledTableCell align="left">{row.genericname}</StyledTableCell>
                                          <StyledTableCell align="center">{row.hospdrugcode}</StyledTableCell>
                                          <StyledTableCell align="center">{row.ndc24 ? row.ndc24 : "-"}</StyledTableCell>
                                          <StyledTableCell align="left">{row.dosageform}</StyledTableCell>
                                      </TableRow>
                                  );
                              })
                            : filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                  return (
                                      <TableRow key={index + 1}>
                                          <StyledTableCell align="left">{row.genericname}</StyledTableCell>
                                          <StyledTableCell align="center">{row.hospdrugcode}</StyledTableCell>
                                          <StyledTableCell align="center">{row.ndc24 ? row.ndc24 : "-"}</StyledTableCell>
                                          <StyledTableCell align="left">{row.dosageform}</StyledTableCell>
                                      </TableRow>
                                  );
                              })}
                    </TableBody>
                </Table>
            </TableContainer>
            {dataDrug.length === 0 ? (
                <Grid container justifyContent="center" my={3}>
                    <Typography noWrap>* ไม่มีข้อมูลในตาราง *</Typography>
                </Grid>
            ) : (
                <TablePagination
                    rowsPerPageOptions={[10, 50, 150, 200]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="จำนวนรายการที่แสดงต่อหน้า"
                />
            )}
        </Paper>
    );
}
